var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-chip-group", [
    _c(
      "div",
      { staticClass: "justify-center v-slide-group__content" },
      [
        _vm._l(_vm.selectedFilters, function (tag) {
          return _c(
            "v-chip",
            {
              key: tag.label,
              staticClass: "ellipsis",
              style: { maxWidth: "120px" },
              attrs: {
                small: "",
                close: "",
                "close-icon": "mdi-close",
                ripple: false,
              },
              on: { "click:close": () => _vm.handleClose(tag) },
            },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-1",
                  attrs: { color: "primary", size: "18" },
                },
                [_vm._v(_vm._s(tag.icon))]
              ),
              _c("span", { staticClass: "ellipsis" }, [
                _vm._v(_vm._s(tag.label)),
              ]),
            ],
            1
          )
        }),
        _vm.selectedFilters.length > 1
          ? _c(
              "v-chip",
              {
                staticClass: "ellipsis",
                style: { maxWidth: "120px", background: "white" },
                attrs: {
                  close: "",
                  small: "",
                  outlined: "",
                  "close-icon": "mdi-close",
                  ripple: false,
                },
                on: { "click:close": _vm.handleClearAll },
              },
              [
                _c("span", { staticClass: "ellipsis" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("timesheetUI.HeaderSearchTimesheet.ClearFilters")
                    )
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }