var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", {
        staticClass: "sr-only",
        attrs: { "aria-live": "assertive" },
        domProps: { textContent: _vm._s(_vm.liveRegionText) },
      }),
      _c("div", { ref: "errorContainer" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
            },
            [
              _vm.timesheetDetailFeatures.percentageOrDuration.data
                .isPercentageAgency
                ? _c(
                    "div",
                    [
                      _vm.timesheetDetailFeatures.percentageOrDuration.data
                        .isPercentageAgency
                        ? _c("v-label", { attrs: { for: "durationInput" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryPercentLabel"
                                )
                              ) + " "
                            ),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("*"),
                            ]),
                          ])
                        : _c("v-label", { attrs: { for: "durationInput" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                                )
                              ) + " "
                            ),
                            _c("span", { staticClass: "required" }, [
                              _vm._v("*"),
                            ]),
                          ]),
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|durationValidation",
                          name: "DurationField",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-text-field", {
                                    ref: "timeEntryInput",
                                    attrs: {
                                      id: "durationInput",
                                      outlined: "",
                                      dense: "",
                                      autofocus: "",
                                      placeholder: "Duration",
                                      "error-messages": errors,
                                      "aria-describedby": "durationInputError",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.handleDurationFocus(errors)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "message",
                                          fn: function ({ message }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  attrs: {
                                                    "aria-live": "polite",
                                                    id: "durationInputError",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(message) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.timesheetDetail
                                          .durationPercentageValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.timesheetDetail,
                                          "durationPercentageValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "timesheetDetail.durationPercentageValue",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1384680250
                        ),
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-label", { attrs: { for: "durationInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|durationValidation",
                          name: "DurationField",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  ref: "timeEntryInput",
                                  attrs: {
                                    id: "durationInput",
                                    outlined: "",
                                    "aria-required": "true",
                                    dense: "",
                                    autofocus: "",
                                    placeholder: "Duration",
                                    "error-messages": errors,
                                    "aria-describedby": "durationInputError",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.handleDurationFocus(errors)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "message",
                                        fn: function ({ message }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                attrs: {
                                                  "aria-live": "polite",
                                                  id: "durationInputError",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(message) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.timesheetDetail.durationValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.timesheetDetail,
                                        "durationValue",
                                        $$v
                                      )
                                    },
                                    expression: "timesheetDetail.durationValue",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
              _vm.timesheetDetailFeatures.location.required
                ? _c(
                    "div",
                    {
                      staticClass: "attached-dropdown",
                      attrs: { id: "locationInput-container" },
                    },
                    [
                      _c("v-label", { attrs: { for: "locationInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: { rules: "required", name: "Location" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-autocomplete", {
                                    ref: "locationInput",
                                    attrs: {
                                      role: "combobox",
                                      id: "locationInput",
                                      attach: "#locationInput-container",
                                      "aria-required": "true",
                                      "menu-props": {
                                        offsetY: true,
                                        nudgeBottom: 28,
                                        contentClass: "location-list-indented",
                                      },
                                      outlined: "",
                                      dense: "",
                                      filter: _vm.customFilter,
                                      items:
                                        _vm.timesheetDetailFeatures.location
                                          .data.locations,
                                      "item-text": "label",
                                      "item-value": "value",
                                      placeholder: _vm.$t(
                                        "timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations"
                                      ),
                                      "error-messages": errors,
                                      clearable: "",
                                    },
                                    on: {
                                      change: _vm.handleLocation,
                                      keydown: _vm.arrowKeyHandler,
                                      focus: function ($event) {
                                        return _vm.handleLocationAnnounce(
                                          errors
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.locationInLocal,
                                      callback: function ($$v) {
                                        _vm.locationInLocal = $$v
                                      },
                                      expression: "locationInLocal",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2882052520
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.timesheetDetailFeatures.location.required
                ? _c("timesheet-manage-preference-location-select", {
                    attrs: {
                      "should-update-location": _vm.updateLocationSettings,
                    },
                  })
                : _vm._e(),
              !_vm.isHideTimesheetWorkcode
                ? _c(
                    "div",
                    {
                      staticClass: "attached-dropdown",
                      attrs: { id: "workcodeInput-container" },
                    },
                    [
                      _c("v-label", { attrs: { for: "workcodeInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryLabelWorkcode"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _vm.selectedPPMTask.jobTitleForBilling ||
                      _vm.timesheetDetail.disablePPMSelect ||
                      (_vm.agencyData.isRigidAgency &&
                        _vm.timesheetDetailFeatures.workcode.data.workCodes
                          .length === 1)
                        ? _c("v-text-field", {
                            attrs: {
                              id: "workcodeInput",
                              value: _vm.computedWorkCodeText,
                              outlined: "",
                              dense: "",
                              disabled: "",
                              "aria-describedby": "workcodeInputError",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "message",
                                  fn: function ({ message }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          attrs: {
                                            "aria-live": "polite",
                                            id: "workcodeInputError",
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(message) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3927237283
                            ),
                          })
                        : _c("validation-provider", {
                            attrs: { rules: "required", name: "Workcode" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ errors }) {
                                    return [
                                      _c("v-autocomplete", {
                                        ref: "workcodeInput",
                                        attrs: {
                                          role: "listbox",
                                          id: "workcodeInput",
                                          attach: "#workcodeInput-container",
                                          "menu-props": {
                                            offsetY: true,
                                            nudgeBottom: 28,
                                            allowOverflow: true,
                                          },
                                          outlined: "",
                                          dense: "",
                                          items: _vm.workCodeItems,
                                          "error-messages": errors,
                                          "item-text": "label",
                                          "item-value": "value",
                                          placeholder: "Workcode",
                                          disabled: _vm.disabledWorkCode,
                                          clearable: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.loadBillableIndicators(
                                              _vm.timesheetDetail.workCode
                                            )
                                          },
                                          keydown: _vm.arrowKeyHandler,
                                          focus: function ($event) {
                                            return _vm.announceError(errors)
                                          },
                                        },
                                        model: {
                                          value: _vm.timesheetDetail.workCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.timesheetDetail,
                                              "workCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "timesheetDetail.workCode",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              347525096
                            ),
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: ["pb-0", { "pt-0": !_vm.isTimeEntryDrawerExpanded }],
              attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "attached-dropdown",
                  attrs: { id: "billableIndicator-container" },
                },
                [
                  _c("v-label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryLabelBI"
                        )
                      ) + " "
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v("*")]),
                  ]),
                  _c("validation-provider", {
                    attrs: { rules: "required", name: "Billable Indicator" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-combobox", {
                              ref: "billableIndicator",
                              attrs: {
                                role: "listbox",
                                id: "billableIndicator",
                                attach: "#billableIndicator-container",
                                "menu-props": {
                                  offsetY: true,
                                  nudgeBottom: 28,
                                },
                                "aria-required": "true",
                                outlined: "",
                                dense: "",
                                items: _vm.timesheetDetailBillableIndicators,
                                "item-text": "label",
                                placeholder: "Billable Indicator",
                                "error-messages": errors,
                                loading: _vm.loadingBI,
                                "return-object": "",
                                disabled:
                                  _vm.isTimesheetApproved ||
                                  _vm.timesheetDetailBillableIndicators
                                    .length <= 1 ||
                                  _vm.timesheetDetail.disablePPMSelect ||
                                  _vm.disablePPMWorkcode,
                              },
                              on: {
                                keydown: _vm.arrowKeyHandler,
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              model: {
                                value: _vm.timesheetDetail.billableIndicator,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetail,
                                    "billableIndicator",
                                    $$v
                                  )
                                },
                                expression: "timesheetDetail.billableIndicator",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.timesheetDetailFeatures.location.required &&
              _vm.agencyData.isWFHActive
                ? _c(
                    "div",
                    {
                      staticClass: "attached-dropdown",
                      attrs: { id: "wfhInput-container" },
                    },
                    [
                      _c("v-label", { attrs: { for: "wfhInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: { rules: "required", name: "Work From Home" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-select", {
                                    ref: "wfhInput",
                                    attrs: {
                                      role: "listbox",
                                      id: "wfhInput",
                                      "aria-required": "true",
                                      outlined: "",
                                      dense: "",
                                      attach: "#wfhInput-container",
                                      items: _vm.wfhOptions,
                                      "item-text": "label",
                                      "item-value": "value",
                                      placeholder: _vm.$t(
                                        "timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel"
                                      ),
                                      "error-messages":
                                        (errors &&
                                          errors.length > 0 && [
                                            _vm.$t(
                                              "timesheetUI.ValidationMessages.WorkFromHome"
                                            ),
                                          ]) ||
                                        [],
                                      "menu-props": {
                                        nudgeBottom: 28,
                                        offsetY: true,
                                        bottom: true,
                                      },
                                    },
                                    on: {
                                      keydown: _vm.arrowKeyHandler,
                                      keyup: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          )
                                            return null
                                          return $event.target.click()
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          )
                                            return null
                                          return $event.target.click()
                                        },
                                      ],
                                      focus: function ($event) {
                                        return _vm.announceError(errors)
                                      },
                                      change: _vm.handlewFHChange,
                                    },
                                    model: {
                                      value: _vm.timesheetDetail.wfh,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.timesheetDetail,
                                          "wfh",
                                          $$v
                                        )
                                      },
                                      expression: "timesheetDetail.wfh",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3731226093
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.timesheetDetailFeatures.location.required &&
              _vm.agencyData.isWFHActive
                ? _c("timesheet-manage-preference-wfh-select", {
                    attrs: { "should-update-wfh": _vm.updateWFHSettings },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c("v-label", { attrs: { for: "commentInput" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelComments"
                        )
                      )
                    ),
                  ]),
                  _c("validation-provider", {
                    attrs: { rules: "maxComment", name: "Comment" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                id: "commentInput",
                                outlined: "",
                                dense: "",
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelCommentsPlaceholder"
                                ),
                                "error-messages": errors,
                                counter: "",
                                maxlength: "125",
                                "aria-describedby": "commentInputError",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "message",
                                    fn: function ({ message }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              "aria-live": "polite",
                                              id: "commentInputError",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(message) + " ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.timesheetDetail.comment,
                                callback: function ($$v) {
                                  _vm.$set(_vm.timesheetDetail, "comment", $$v)
                                },
                                expression: "timesheetDetail.comment",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }