var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _vm.isProjectAlreadyAddedToTimesheet()
        ? _c("DocCheckIcon", {
            staticStyle: {
              position: "absolute",
              "z-index": "1",
              left: "-14px",
              top: "6px",
            },
          })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "mb-3", attrs: { outlined: "" } },
        [
          _c("v-card-text", { staticClass: "px-5 py-3" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center gap-6" },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-grow-1 font-size--10 job-details__meta",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-color--primary font-weight--600 mb-2 font-size--12",
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.job.jobName))])]
                    ),
                    _c(
                      "div",
                      { staticClass: "accent--text mb-1" },
                      [
                        _c("span", { staticClass: "mr-1 font-color--body" }, [
                          _vm._v("•"),
                        ]),
                        _vm.isJobAssignedProject
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timesheetUI.TimesheetJobs.ClarizenAssignProjectLabel"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "timesheetUI.TimesheetJobs.ClarizenAssignTaskLabel"
                                  )
                                )
                              ),
                            ]),
                        _c(
                          "v-icon",
                          {
                            staticClass: "cross-company-rule__label",
                            attrs: { color: "accent", size: 10 },
                          },
                          [_vm._v("mdi-check")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-wrap secondary--text" },
                      [
                        _c("span", { staticClass: "mr-1" }, [
                          _vm._v(_vm._s(_vm.job.clientName)),
                        ]),
                        _vm._v(" • "),
                        _c("span", { staticClass: "mx-1" }, [
                          _vm._v(_vm._s(_vm.job.agencyName)),
                        ]),
                        _vm._v(" • "),
                        _c("span", { staticClass: "ml-1" }, [
                          _vm._v(_vm._s(_vm.job.job_ID)),
                        ]),
                      ]
                    ),
                    _vm.job.crossCompanyRuleTypeName
                      ? _c(
                          "div",
                          { staticClass: "accent--text my-1" },
                          [
                            _c("span", { staticClass: "mr-1" }, [_vm._v("•")]),
                            _c("span", { staticClass: "font-size--10" }, [
                              _vm._v(_vm._s(_vm.job.crossCompanyRuleTypeName)),
                            ]),
                            _c(
                              "v-icon",
                              {
                                staticClass: "cross-company-rule__label",
                                attrs: { color: "accent", size: 10 },
                              },
                              [_vm._v("mdi-star")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.job.clarizenTaskName ||
                    _vm.job.isClarizenAssignedProject
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "font-size--10 font-weight--600 accent-light primary--text rounded-lg text-uppercase px-2 mt-2 d-inline-block",
                            staticStyle: {
                              "padding-top": "2px",
                              "padding-bottom": "2px",
                            },
                          },
                          [
                            _vm.job.isClarizenTask &&
                            _vm.job.clarizenTaskExternalId
                              ? [_vm._v(_vm._s(_vm.job.clarizenTaskName))]
                              : _vm._e(),
                            _vm.job.isClarizenAssignedProject &&
                            !_vm.job.clarizenTaskExternalId
                              ? [_vm._v(_vm._s(_vm.job.clarizenProjectName))]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.job.isClarizenAssignedProject
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-wrap mt-2 justify-space-between align-center",
                          },
                          [
                            _c(
                              "v-label",
                              {
                                staticClass:
                                  "primary--text font-size--12 font-weight-regular w-60px",
                                attrs: { for: "PPMTaskDropdown" },
                              },
                              [_vm._v("PPM task")]
                            ),
                            _c("VSelect", {
                              ref: "joblist_ppm_select",
                              staticClass: "font-size--14",
                              attrs: {
                                value: _vm.selectedTask.PPMTask || "",
                                items:
                                  (_vm.PPMTasksByJobId[_vm.job.job_ID] &&
                                    _vm.PPMTasksByJobId[_vm.job.job_ID].data) ||
                                  [],
                                loading:
                                  _vm.PPMTasksByJobId[_vm.job.job_ID] &&
                                  _vm.PPMTasksByJobId[_vm.job.job_ID]
                                    .isDataLoading,
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder"
                                ),
                                "item-text": "label",
                                "item-value": "value",
                                outlined: "",
                                "menu-props": {
                                  bottom: true,
                                  offsetY: true,
                                  nudgeBottom: 0,
                                },
                                id: "PPMTaskDropdown",
                                dense: "",
                                "hide-details": "",
                                "append-icon": "mdi-chevron-down",
                                error: !_vm.validations.PPMTask.valid,
                                "aria-describedby": _vm.validations.PPMTask.msg
                                  ? "ppmTaskError"
                                  : null,
                              },
                              on: {
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.loadPPMTasks(
                                      _vm.job,
                                      _vm.PPMTasksByJobId[_vm.job.job_ID]
                                    )
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "up",
                                        38,
                                        $event.key,
                                        ["Up", "ArrowUp"]
                                      )
                                    )
                                      return null
                                    return _vm.announceChange("PPMTask")
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "down",
                                        40,
                                        $event.key,
                                        ["Down", "ArrowDown"]
                                      )
                                    )
                                      return null
                                    return _vm.announceChange("PPMTask")
                                  },
                                ],
                                input: function ($event) {
                                  return _vm.setSelectedTask(
                                    _vm.job.job_ID,
                                    "PPMTask",
                                    $event
                                  )
                                },
                                click: function ($event) {
                                  return _vm.loadPPMTasks(
                                    _vm.job,
                                    _vm.PPMTasksByJobId[_vm.job.job_ID]
                                  )
                                },
                                "update:list-index": (index) =>
                                  _vm.setHighlightedTaskForSR(index, "PPMTask"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "no-data",
                                    fn: function () {
                                      return [
                                        !_vm.PPMTasksByJobId[_vm.job.job_ID] ||
                                        (_vm.PPMTasksByJobId[_vm.job.job_ID] &&
                                          _vm.PPMTasksByJobId[_vm.job.job_ID]
                                            .isDataLoading)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "VListItem",
                                                  [
                                                    _c(
                                                      "VListItem",
                                                      [
                                                        _c(
                                                          "VListItemContent",
                                                          [
                                                            _c(
                                                              "VListItemTitle",
                                                              [
                                                                _vm._v(
                                                                  "Loading..."
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c(
                                                  "VListItem",
                                                  [
                                                    _c(
                                                      "VListItemContent",
                                                      [
                                                        _c("VListItemTitle", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "General.Nodataavailable"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1094706776
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", {
                      ref: "announceRegionPPMTask",
                      staticClass: "sr-only",
                      attrs: { "aria-live": "polite", "aria-atomic": "true" },
                    }),
                    _c(
                      "div",
                      {
                        key: _vm.highlightedTask.PPMTask,
                        staticClass: "sr-only",
                        attrs: { "aria-live": "polite", "aria-atomic": "true" },
                      },
                      [_vm._v(" " + _vm._s(_vm.highlightedTask.PPMTask) + " ")]
                    ),
                    _vm.validations.PPMTask.msg
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "job-validation-message error--text mt-2",
                            attrs: {
                              id: "ppmTaskError",
                              role: "alert",
                              "aria-live": "assertive",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.validations.PPMTask.msg) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.isAltairTaskRequired
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-wrap mt-2 justify-space-between align-center",
                          },
                          [
                            _c(
                              "VLabel",
                              {
                                staticClass:
                                  "primary--text font-size--12 font-weight-regular w-60px",
                                attrs: { for: "altairTaskDropdown" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timesheetUI.EntryFormLabels.TimesheetEntryLabelTask"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("VSelect", {
                              ref: "joblist_altair_select",
                              staticClass: "font-size--14",
                              attrs: {
                                value: _vm.selectedTask.altairTask || "",
                                items:
                                  (_vm.altairTasksByJobId[_vm.job.job_ID] &&
                                    _vm.altairTasksByJobId[_vm.job.job_ID]
                                      .data) ||
                                  [],
                                loading:
                                  _vm.altairTasksByJobId[_vm.job.job_ID] &&
                                  _vm.altairTasksByJobId[_vm.job.job_ID]
                                    .isDataLoading,
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder"
                                ),
                                "item-text": "label",
                                "item-value": "value",
                                outlined: "",
                                dense: "",
                                "menu-props": {
                                  bottom: true,
                                  offsetY: true,
                                  nudgeBottom: 0,
                                },
                                id: "altairTaskDropdown",
                                "append-icon": "mdi-chevron-down",
                                "hide-details": "",
                                "aria-describedby": _vm.validations.altairTask
                                  .msg
                                  ? "altairTaskError"
                                  : null,
                              },
                              on: {
                                keydown: [
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "space",
                                        32,
                                        $event.key,
                                        [" ", "Spacebar"]
                                      )
                                    )
                                      return null
                                    $event.preventDefault()
                                    return _vm.loadAltairTasks(
                                      _vm.job,
                                      _vm.altairTasksByJobId[_vm.job.job_ID]
                                    )
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "up",
                                        38,
                                        $event.key,
                                        ["Up", "ArrowUp"]
                                      )
                                    )
                                      return null
                                    return _vm.announceChange("altairTask")
                                  },
                                  function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "down",
                                        40,
                                        $event.key,
                                        ["Down", "ArrowDown"]
                                      )
                                    )
                                      return null
                                    return _vm.announceChange("altairTask")
                                  },
                                ],
                                input: function ($event) {
                                  return _vm.setSelectedTask(
                                    _vm.job.job_ID,
                                    "altairTask",
                                    $event
                                  )
                                },
                                click: function ($event) {
                                  return _vm.loadAltairTasks(
                                    _vm.job,
                                    _vm.altairTasksByJobId[_vm.job.job_ID]
                                  )
                                },
                                "update:list-index": (index) =>
                                  _vm.setHighlightedTaskForSR(
                                    index,
                                    "altairTask"
                                  ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "no-data",
                                    fn: function () {
                                      return [
                                        !_vm.altairTasksByJobId[
                                          _vm.job.job_ID
                                        ] ||
                                        (_vm.altairTasksByJobId[
                                          _vm.job.job_ID
                                        ] &&
                                          _vm.altairTasksByJobId[_vm.job.job_ID]
                                            .isDataLoading)
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "VListItem",
                                                  [
                                                    _c(
                                                      "VListItemContent",
                                                      [
                                                        _c("VListItemTitle", [
                                                          _vm._v("Loading..."),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c(
                                                  "VListItem",
                                                  [
                                                    _c(
                                                      "VListItemContent",
                                                      [
                                                        _c("VListItemTitle", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "General.Nodataavailable"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                4001561533
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", {
                      ref: "announceRegionAltairTask",
                      staticClass: "sr-only",
                      attrs: { "aria-live": "polite", "aria-atomic": "true" },
                    }),
                    _c(
                      "div",
                      {
                        key: _vm.highlightedTask.altairTask,
                        staticClass: "sr-only",
                        attrs: { "aria-live": "polite", "aria-atomic": "true" },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.highlightedTask.altairTask) + " "
                        ),
                      ]
                    ),
                    _vm.validations.altairTask.msg
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "job-validation-message error--text mt-2",
                            attrs: {
                              id: "altairTaskError",
                              role: "alert",
                              "aria-live": "assertive",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.validations.altairTask.msg) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "pl-1 pr-2 font-weight--600",
                    attrs: {
                      depressed: "",
                      text: "",
                      small: "",
                      disabled: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addAssignedJobToTimesheet(_vm.job)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "d-flex align-center" },
                      [
                        !_vm.isJobAssignedProject
                          ? [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-plus"),
                              ]),
                              _c(
                                "span",
                                {
                                  attrs: {
                                    "aria-label": `${
                                      _vm.job.job_Name || _vm.job.jobName
                                    }, ${
                                      !_vm.validations.altairTask.valid
                                        ? _vm.$t(
                                            "timesheetUI.SearchJobs.CantBeAddToTimesheet"
                                          )
                                        : _vm.$t(
                                            "timesheetUI.SearchJobs.BtnAddToTimesheet"
                                          )
                                    } job`,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.validations.altairTask.valid
                                          ? _vm.$t(
                                              "timesheetUI.SearchJobs.CantBeAddToTimesheet"
                                            )
                                          : _vm.$t(
                                              "timesheetUI.SearchJobs.BtnAddToTimesheet"
                                            )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm.isJobAssignedProject
                          ? [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    "aria-label": `${
                                      _vm.job.job_Name || _vm.job.jobName
                                    }, ${_vm.$t(
                                      "timesheetUI.SearchJobs.BtnAddToTimesheet"
                                    )} job`,
                                  },
                                },
                                [
                                  _vm.isProjectAlreadyAddedToTimesheet() &&
                                  _vm.selectedTask.PPMTask &&
                                  ((!_vm.isAltairTaskRequired &&
                                    !_vm.isPPMTaskAlreadyAddedToTimesheet()) ||
                                    (_vm.isAltairTaskRequired &&
                                      !_vm.isAltairAndPPMTaskAlreadyAddedToTimesheet(
                                        _vm.job.job_ID
                                      )))
                                    ? [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "accent" } },
                                          [_vm._v("mdi-plus")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-size accent--text",
                                            style: `opacity: ${
                                              _vm.loading ? 0.26 : 1
                                            }`,
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("General.Addnewrow")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm.isPPMTaskAlreadyAddedToTimesheet()
                                    ? [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-close")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "font-size" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheetUI.SearchJobs.CantBeAddToTimesheet"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-plus")]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "timesheetUI.SearchJobs.BtnAddToTimesheet"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }