var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-container grow" },
    [
      _c("v-text-field", {
        staticClass: "timesheet-header__search",
        attrs: {
          placeholder: _vm.$t("timesheetUI.HeaderSearchTimesheet.Placeholder"),
          "aria-label": _vm.$t("timesheetUI.HeaderSearchTimesheet.Placeholder"),
          outlined: "",
          "hide-details": "",
          dense: "",
          clearable: "",
          tabindex: "0",
        },
        on: { input: _vm.handleSearchInput },
        scopedSlots: _vm._u([
          {
            key: "prepend-inner",
            fn: function () {
              return [_c("search-filter-chips")]
            },
            proxy: true,
          },
          {
            key: "append",
            fn: function () {
              return [
                _c("search-filter-popover"),
                _c("v-icon", { attrs: { color: "primary" } }, [
                  _vm._v(" mdi-magnify "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm.searchFeedback && _vm.search && _vm.search.length
        ? _c("div", {
            staticClass: "search-feedback",
            attrs: { "aria-live": "polite" },
            domProps: { innerHTML: _vm._s(_vm.searchFeedback) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }