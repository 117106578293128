var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showDailyDailyActionRow
        ? _c("GridRowWithoutPageOffset", {
            attrs: { "no-borders": "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "col1",
                  fn: function () {
                    return [
                      _c("GridCell", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center flex-wrap" },
                          [
                            _vm.agencyData.enableAbsenceModule &&
                            _vm.absenceModule
                              ? _c(
                                  "div",
                                  { staticClass: "mr-2" },
                                  [
                                    _c("absence-form-create", {
                                      attrs: { selectedDate: _vm.selectedDate },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isAnyJobPresentOnTimesheet
                              ? _c("CopyJobsFromPreviousWeek", {
                                  staticClass: "font-size--14",
                                  attrs: { height: "36" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                _vm._l(_vm.calendar, function (day, i) {
                  return {
                    key: day.dayName.toLowerCase(),
                    fn: function () {
                      return [
                        _vm.isAnyJobPresentOnTimesheet
                          ? [
                              _c(
                                "GridCell",
                                { key: i, attrs: { "no-gutters": "" } },
                                [
                                  !_vm.isHiddenJobsScreen
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "d-flex justify-center",
                                        },
                                        [
                                          _c("DailySubmitButton", {
                                            attrs: {
                                              "aria-label": _vm.getAriaLabel(
                                                i,
                                                day
                                              ),
                                              dayStatus: _vm.dailyStatus[i],
                                              submittingTimesheets:
                                                _vm.submittingTimesheets,
                                            },
                                            on: {
                                              click: () => {
                                                _vm.dailyStatus[i].state ===
                                                  "enabled" &&
                                                  _vm.timesheetSubmitDay(i, day)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  }
                }),
                _vm.isAnyJobPresentOnTimesheet
                  ? {
                      key: "total",
                      fn: function () {
                        return [
                          _c("GridCell", { attrs: { "no-gutters": "" } }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    class: [
                                      "v-btn--daily v-btn--daily-submit-all",
                                      { disabled: false },
                                    ],
                                    attrs: {
                                      small: "",
                                      depressed: "",
                                      rounded: "",
                                      id: "btn-submit",
                                      color: "primary",
                                      disabled:
                                        !(
                                          _vm.timesheetCanBeSubmitted &&
                                          _vm.timesheetUnsubmittedAllDays
                                        ) || _vm.submittingTimesheets,
                                    },
                                    on: { click: _vm.timesheetSubmitWeek },
                                  },
                                  [
                                    _vm.submittingTimesheets
                                      ? _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "timesheetUI.Btns.BtnsSaving"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm.$vuetify.breakpoint.lgAndUp
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheetUI.Btns.BtnSubmitAll"
                                                    )
                                                  )
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheetUI.MobileBtns.BtnSubmitAll"
                                                    )
                                                  )
                                                ),
                                              ]),
                                        ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _vm.showDailyWeeklyOrWeeklyWeeklyActionRow
        ? _c(
            "GridRowWithoutPageOffset",
            { staticClass: "py-2", attrs: { "no-borders": "" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-between" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-grow-1" },
                    [
                      _vm.agencyData.enableAbsenceModule && _vm.absenceModule
                        ? _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c("absence-form-create", {
                                attrs: { selectedDate: _vm.selectedDate },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isAnyJobPresentOnTimesheet
                        ? _c("CopyJobsFromPreviousWeek", {
                            staticClass: "font-size--14",
                            attrs: { height: "36" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.isAnyJobPresentOnTimesheet
                    ? _c(
                        "v-btn",
                        {
                          class: [
                            "v-btn--custom-icon v-btn--long ",
                            {
                              "v-btn--fullwidth":
                                _vm.$vuetify.breakpoint.smAndDown,
                            },
                            { disabled: false },
                          ],
                          attrs: {
                            depressed: "",
                            rounded: "",
                            id: "btn-submit",
                            color: "primary",
                            disabled:
                              !(
                                _vm.timesheetCanBeSubmitted &&
                                _vm.timesheetUnsubmittedAllDays
                              ) || _vm.submittingTimesheets,
                          },
                          on: { click: _vm.timesheetSubmitWeek },
                        },
                        [
                          _vm.submittingTimesheets
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("timesheetUI.Btns.BtnsSaving")
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timesheetUI.Btns.BtnSubmitTimesheet"
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("timesheet-dialog-self-consent", {
        attrs: { dialogTitle: _vm.selfConsentDialogTitle },
        on: { submitTimesheet: _vm.submitTimesheetDayOrWeek },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }