<template>
  <div class="">
    <div aria-live="assertive" class="sr-only" v-text="liveRegionText"></div>
    <div ref="errorContainer"></div>
    <v-row>
      <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12" class="pb-0">
        <!-- Percentage User Duration -->
        <div
          v-if="
            timesheetDetailFeatures.percentageOrDuration.data.isPercentageAgency
          "
        >
          <v-label for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryDuration') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required|durationValidation"
            name="DurationField"
          >
            <v-text-field
              id="durationInput"
              aria-required="true"
              outlined
              dense
              autofocus
              placeholder="Duration"
              ref="timeEntryInput"
              v-model="timesheetDetail.durationPercentageValue"
              :error-messages="errors"
              aria-describedby="durationInputError"
              @focus="handleDurationFocus(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="durationInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- Non Percentage User Duration -->
        <div v-else>
          <v-label for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryDuration') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required|durationValidation"
            name="DurationField"
          >
            <v-text-field
              id="durationInput"
              aria-required="true"
              outlined
              dense
              autofocus
              placeholder="Duration"
              ref="timeEntryInput"
              v-model="timesheetDetail.durationValue"
              :error-messages="errors"
              aria-describedby="durationInputError"
              @focus="handleDurationFocus(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="durationInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- Location -->
        <div
          id="locationInput-container"
          class="attached-dropdown"
          v-if="
            firstAvailableContract.country === 'US' &&
            timesheetDetailFeatures.location.required
          "
        >
          <v-label for="locationInput"
            >{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations')
            }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Location"
          >
            <v-autocomplete
              role="combobox"
              id="locationInput"
              attach="#locationInput-container"
              ref="locationInput"
              aria-required="true"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
                contentClass: 'location-list-indented',
              }"
              outlined
              dense
              :filter="customFilter"
              :items="timesheetDetailFeatures.location.data.locations"
              v-model="locationInLocal"
              item-text="label"
              item-value="value"
              :placeholder="
                $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations')
              "
              :error-messages="errors"
              @change="handleLocation"
              @keydown="arrowKeyHandler"
              clearable
              @focus="announceError(errors)"
            ></v-autocomplete>
          </validation-provider>
        </div>

        <!-- Rest of the week/ Full Day -->
        <timesheet-manage-preference-location-select
          v-if="
            firstAvailableContract.country === 'US' &&
            timesheetDetailFeatures.location.required
          "
          :should-update-location="updateLocationSettings"
        />

        <!-- Studio Activity -->
        <div class="attached-dropdown" id="activityInput-container">
          <v-label for="activityInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetStudioActivity') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Studio Activity"
          >
            <v-autocomplete
              role="listbox"
              aria-required="true"
              id="activityInput"
              attach="#activityInput-container"
              ref="activityInput"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
                allowOverflow: true,
              }"
              outlined
              dense
              v-model="timesheetDetail.activity"
              :items="timesheetDetailFeatures.activity.data.activities"
              :error-messages="errors"
              @change="doPSLogicActivity"
              item-text="label"
              item-value="value"
              :placeholder="
                $t('timesheetUI.EntryFormLabels.TimesheetStudioActivity')
              "
              @keydown="arrowKeyHandler"
              clearable
              @focus="announceError(errors)"
            ></v-autocomplete>
          </validation-provider>
        </div>
      </v-col>
      <v-col
        :cols="isTimeEntryDrawerExpanded ? 6 : 12"
        :class="['pb-0', { 'pt-0': !isTimeEntryDrawerExpanded }]"
      >
        <!-- Billable Indicator -->
        <div id="billableIndicator-container" class="attached-dropdown">
          <v-label for="billableIndicator"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelBI') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Billable Indicator"
          >
            <v-combobox
              role="listbox"
              id="billableIndicator"
              ref="billableIndicator"
              aria-required="true"
              outlined
              attach="#billableIndicator-container"
              dense
              :items="timesheetDetailBillableIndicators"
              v-model="timesheetDetail.billableIndicator"
              item-text="label"
              placeholder="Billable Indicator"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
              }"
              :error-messages="errors"
              :loading="loadingBI"
              return-object
              :disabled="
                isTimesheetApproved ||
                timesheetDetailBillableIndicators.length <= 1 ||
                timesheetDetail.disablePPMSelect ||
                disablePPMWorkcode
              "
              @keydown="arrowKeyHandler"
              @focus="announceError(errors)"
            ></v-combobox>
          </validation-provider>
        </div>

        <!-- Product Reference -->
        <div id="productReferenceInput-container" class="attached-dropdown">
          <v-label for="productReferenceInput"
            >{{
              $t(
                'timesheetUI.EntryFormLabels.TimesheetEntryProductReferenceLabel'
              )
            }}
            <span class="required" v-if="timesheetDetail.productQuantity"
              >*</span
            ></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            :rules="`${timesheetDetail.productQuantity ? 'required' : ''}`"
            name="Product Reference"
          >
            <v-autocomplete
              role="listbox"
              id="productReferenceInput"
              ref="productReferenceInput"
              aria-required="true"
              outlined
              attach="#productReferenceInput-container"
              dense
              :menu-props="{
                nudgeBottom: 28,
                offsetY: true,
              }"
              v-model="timesheetDetail.productReference"
              :items="timesheetDetailFeatures.productRef.data?.productRefs"
              :error-messages="errors"
              item-text="label"
              item-value="value"
              :placeholder="
                $t(
                  'timesheetUI.EntryFormLabels.TimesheetEntryProductReferenceLabel'
                )
              "
              clearable
              @click:clear="timesheetDetail.productQuantity = null"
              @keydown="arrowKeyHandler"
              @focus="announceError(errors)"
            ></v-autocomplete>
          </validation-provider>
        </div>

        <!-- WFH -->
        <div
          v-if="
            timesheetDetailFeatures.location.required && agencyData.isWFHActive
          "
          id="wfhInput-container"
          class="attached-dropdown"
        >
          <v-label for="wfhInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Work From Home"
          >
            <v-select
              role="listbox"
              id="wfhInput"
              ref="wfhInput"
              attach="#wfhInput-container"
              aria-required="true"
              outlined
              dense
              :menu-props="{
                nudgeBottom: 28,
                offsetY: true,
                bottom: true,
              }"
              :items="wfhOptions"
              v-model="timesheetDetail.wfh"
              item-text="label"
              item-value="value"
              :placeholder="
                $t('timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel')
              "
              :error-messages="errors"
              @keyup.down="$event.target.click()"
              @keyup.up="$event.target.click()"
              @focus="announceError(errors)"
              @change="handlewFHChange"
            ></v-select>
          </validation-provider>
        </div>

        <!-- Rest of the week/ Full Day -->
        <timesheet-manage-preference-wfh-select
          v-if="
            timesheetDetailFeatures.location.required && agencyData.isWFHActive
          "
          :should-update-wfh="updateWFHSettings"
        />

        <!-- Product Quantity -->
        <div>
          <v-label for="productQuantityInput"
            >{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryQuantityLabel')
            }}
            <span class="required" v-if="timesheetDetail.productReference"
              >*</span
            ></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            :rules="`${
              timesheetDetail.productReference ? 'required|' : ''
            }quantityValidation`"
            name="Product Quantity"
          >
            <v-text-field
              id="productQuantityInput"
              aria-required="true"
              outlined
              dense
              v-model="timesheetDetail.productQuantity"
              :error-messages="errors"
              @input="checkProductRef"
              :placeholder="
                $t('timesheetUI.EntryFormLabels.TimesheetEntryQuantityLabel')
              "
              maxLength="15"
              aria-describedby="productQuantityInputError"
              @focus="announceError(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="productQuantityInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- Comment -->
        <div>
          <v-label for="commentInput">{{
            $t('timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelComments')
          }}</v-label>
          <validation-provider
            v-slot="{ errors }"
            rules="maxComment"
            name="Comment"
          >
            <v-text-field
              id="commentInput"
              outlined
              dense
              :placeholder="
                $t(
                  'timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelCommentsPlaceholder'
                )
              "
              v-model="timesheetDetail.comment"
              :error-messages="errors"
              counter
              maxlength="125"
              aria-describedby="commentInputError"
              @focus="announceError(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="commentInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import TimesheetManagePreferenceLocationSelect from './TimesheetManagePreferenceLocationSelect.vue'
import TimesheetManagePreferenceWfhSelect from './TimesheetManagePreferenceWfhSelect.vue'

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import {
  arrowKeyHandler as arrowKeyHandlerClick,
  handleArrowKeys,
} from '@Shared/filters'
import { uniqBy, debounce } from 'lodash'
import i18n from '@Shared/i18n'
import { announceErrorHandler } from '@Shared/announceError'

export default {
  data() {
    return {
      locationSettings: null,
      WfhSetting: null,
      locationInLocal: null,
      wfhOptions: [
        {
          label: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryWfhYes',
            window.CULTURE
          ),
          value: true,
        },
        {
          label: i18n.t(
            'timesheetUI.EntryFormLabels.TimesheetEntryWfhNo',
            window.CULTURE
          ),
          value: false,
        },
      ],
      liveRegionText: '',
      updateLocationSettings: null,
      updateWFHSettings: null,
    }
  },
  components: {
    ValidationProvider,
    TimesheetManagePreferenceLocationSelect,
    TimesheetManagePreferenceWfhSelect,
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'timesheetDetailBillableIndicators',
      'timesheetDetailFeatures',
      'isTimeEntryDrawerExpanded',
      'loadingBI',
      'agencyData',
      'observer',
      'firstAvailableContract',
      'timesheetSelectedJob',
    ]),
    ...mapGetters('storeTimesheets', [
      'isSelfAssignmentv1_4',
      'isTimesheetApproved',
    ]),
    disablePPMWorkcode: {
      cache: false,
      get() {
        if (
          this.timesheetSelectedJob.job?.isClarizenTask ||
          this.timesheetSelectedJob.job?.isClarizenAssignedProject ||
          this.timesheetSelectedJob.job?.selfAssignmentWithManageTasking
        ) {
          return true
        }
        return false
      },
    },
  },
  watch: {
    'timesheetDetail.location': {
      handler() {
        this.checkLocationExists()
      },
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setLocation',
      'setHasTimeEntryChanged',
    ]),
    ...mapActions('storeTimesheets', [
      'loadBillableIndicators',
      'loadValidationLogic',
      'loadWorkcodeandBillableIndicators',
    ]),
    handleDurationFocus(errors) {
      this.$refs.timeEntryInput.$el.querySelector('input').select()
      this.$emit('focus')
      this.announceError(errors)
    },
    announceError(errors) {
      announceErrorHandler(errors[0], this.$refs.errorContainer)
    },
    checkProductRef() {
      if (!this.timesheetDetail.productReference) {
        setTimeout(() => {
          this.observer.validate()
        }, 200)
      }
    },
    arrowKeyHandler: debounce(function ($e) {
      arrowKeyHandlerClick($e)

      handleArrowKeys(this, $e, [
        'billableIndicator',
        'billableIndicator',
        'locationInput',
        'activityInput',
        'productReferenceInput',
        'wfhInput',
      ])
    }, 300),
    customFilter(item, queryText, itemText) {
      if (item.header) {
        const results = uniqBy(
          this.timesheetDetailFeatures.location.data.locations.filter(
            (a) =>
              a.label &&
              a.label
                .toLocaleLowerCase()
                .indexOf(queryText.toLocaleLowerCase()) > -1
          ),
          'country'
        ).map((a) => a.country)
        return results?.includes(item.header)
      }

      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      )
    },
    doPSLogicActivity() {
      if (
        !this.timesheetDetailFeatures.clarizenCustomTask.required &&
        !this.isSelfAssignmentv1_4
      ) {
        this.loadBillableIndicators(this.timesheetDetail.activity)
      }
    },
    handleLocation($e) {
      const selectedLocation =
        this.timesheetDetailFeatures.location.data.locations.find(
          (location) => location.value === $e
        )
      this.timesheetDetail.location = selectedLocation?.label
      this.timesheetDetail.countryCode = selectedLocation?.countryCode
      this.timesheetDetail.locationLabel = selectedLocation?.label
      this.timesheetDetail.locationCode = selectedLocation?.value

      this.updateLocationSettings = Date.now()
    },
    handlewFHChange() {
      this.updateWFHSettings = Date.now()
    },
    checkLocationExists() {
      if (this.timesheetDetailFeatures.location?.data?.locations) {
        const locations = this.timesheetDetailFeatures.location.data.locations

        const found = locations.find((a) => {
          if (
            this.timesheetDetail.locationCode === a.value &&
            this.timesheetDetail.countryCode === a.countryCode
          ) {
            return a
          }
        })

        if (!found) {
          locations.push({
            country: null,
            label: this.timesheetDetail.location,
            value: this.timesheetDetail.locationCode,
            selected: true,
            countryCode: this.timesheetDetail.countryCode,
          })
        }
        if (this.timesheetDetail.locationCode.length > 0) {
          this.locationInLocal = this.timesheetDetail.locationCode
        }
      }
    },
  },
  mounted() {
    this.loadValidationLogic()
    this.checkLocationExists()
    this.loadWorkcodeandBillableIndicators()
  },
}
</script>

<style lang="scss" scoped>
.attached-dropdown::v-deep {
  position: relative;
}
</style>
