var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap mt-2 justify-space-between align-center" },
    [
      _c("span", { staticClass: "primary--text font-size--12 w-60px" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("timesheetUI.EntryFormLabels.TimesheetEntryLabelTask")
            )
        ),
      ]),
      _c("VSelect", {
        ref: "joblist_altair_select",
        staticClass: "font-size--14",
        attrs: {
          value: _vm.selectedTasks.altairTask || "",
          items:
            (_vm.altairTasksByJobId[_vm.job.job_ID] &&
              _vm.altairTasksByJobId[_vm.job.job_ID].data) ||
            [],
          loading:
            _vm.altairTasksByJobId[_vm.job.job_ID] &&
            _vm.altairTasksByJobId[_vm.job.job_ID].isDataLoading,
          placeholder: _vm.$t(
            "timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder"
          ),
          "item-text": "label",
          "item-value": "value",
          outlined: "",
          attach: "",
          dense: "",
          "menu-props": {
            bottom: true,
            offsetY: true,
            nudgeBottom: 0,
          },
          "hide-details": "",
          "append-icon": "mdi-chevron-down",
          error: _vm.error,
        },
        on: {
          input: function ($event) {
            return _vm.setSelectedTask(_vm.job.job_ID, "altairTask", $event)
          },
          click: function ($event) {
            return _vm.loadAltairTasks(
              _vm.job,
              _vm.altairTasksByJobId[_vm.job.job_ID]
            )
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-data",
            fn: function () {
              return [
                !_vm.altairTasksByJobId[_vm.job.job_ID] ||
                (_vm.altairTasksByJobId[_vm.job.job_ID] &&
                  _vm.altairTasksByJobId[_vm.job.job_ID].isDataLoading)
                  ? _c(
                      "div",
                      [
                        _c(
                          "VListItem",
                          [
                            _c(
                              "VListItem",
                              [
                                _c(
                                  "VListItemContent",
                                  [
                                    _c("VListItemTitle", [
                                      _vm._v("Loading..."),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "VListItem",
                          [
                            _c(
                              "VListItemContent",
                              [
                                _c("VListItemTitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("General.Nodataavailable"))
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }