var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-end align-center mt-4" },
    [
      this.getPreferenceLocationLabel
        ? _c("span", { staticClass: "font-size--16 pr-2 location-label" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("timesheetUI.ManagePreference.SelectedLocation")
                ) +
                ": "
            ),
            _c("b", { staticClass: "font-weight--600" }, [
              _vm._v(_vm._s(this.getPreferenceLocationLabel)),
            ]),
          ])
        : _vm._e(),
      _c(
        "v-btn",
        {
          staticClass: "font-size--16",
          attrs: { color: "accent", text: "", "x-small": "" },
          on: { click: _vm.showPreferenceDialog },
        },
        [
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "timesheetUI.ManagePreference.ManageLocationPreferenceText"
                  )
                ) +
                " "
            ),
          ],
          _vm.agencyData.isWFHActive
            ? [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "timesheetUI.ManagePreference.ManageWFHPreferenceText"
                      )
                    ) +
                    " "
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }