var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800", persistent: _vm.loading },
          model: {
            value: _vm.absenceFormModifyDialog,
            callback: function ($$v) {
              _vm.absenceFormModifyDialog = $$v
            },
            expression: "absenceFormModifyDialog",
          },
        },
        [
          _c(
            "FocusLock",
            { attrs: { returnFocus: "" } },
            [
              _vm.cancellationStatus === "default"
                ? _c(
                    "v-card",
                    {
                      staticClass: "relative d-flex flex-column",
                      attrs: { "min-height": "500px" },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "justify-space-between py-5" },
                        [
                          _c(
                            "h2",
                            { staticClass: "font-weight--600 text-h2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timesheetUI.Absence.AbsenceModifyTitle"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticClass: "flex-grow-1" },
                        [
                          _c(
                            "h3",
                            { staticClass: "font-weight--900 my-6 d-flex" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "timesheetUI.Absence.AbsenceCollapsedTitle"
                                    )
                                  ) +
                                  " (" +
                                  _vm._s(_vm.dateRange) +
                                  ") "
                              ),
                            ]
                          ),
                          _c("v-data-table", {
                            staticClass: "absence-modify-table",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tableData,
                              dense: "",
                              "sort-by": "StartDate",
                              "hide-default-footer": _vm.tableData.length <= 10,
                              "item-key": "AbsenceRequestID",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.actions`,
                                  fn: function ({ item }) {
                                    return [
                                      ![
                                        _vm.absenceStatusMap.cancelled,
                                        _vm.absenceStatusMap.rejected,
                                        _vm.absenceStatusMap
                                          .cancellationPending,
                                        _vm.absenceStatusMap
                                          .cancellationApproved,
                                      ].includes(item.status)
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                small: "",
                                                depressed: "",
                                                rounded: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelAbsenceValidate(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheetUI.Btns.BtnBookAbsenceCancel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "py-4 justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight--600",
                              attrs: { outlined: "", rounded: "" },
                              on: { click: _vm.closeModal },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("General.Btns.Close")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cancellationStatus !== "default"
                ? _c(
                    "v-card",
                    {
                      staticClass: "relative d-flex flex-column",
                      attrs: { "min-height": "500px" },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "justify-space-between py-5" },
                        [
                          _c(
                            "h2",
                            { staticClass: "font-weight--600 text-h2" },
                            [
                              _vm.cancellationStatus === "ongoing"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timesheetUI.Absence.AbsenceModifyTitleOngoing"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timesheetUI.Absence.AbsenceModifyTitleCancel"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          ),
                        ]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticClass: "flex-grow-1" },
                        [
                          _c(
                            "h3",
                            { staticClass: "font-weight--900 my-8 d-flex" },
                            [
                              _vm.cancellationStatus === "ongoing"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timesheetUI.Absence.AbsenceModifyOngoingAlert"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.cancellationStatus === "future"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timesheetUI.Absence.AbsenceModifyFutureAlert"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.cancellationStatus === "past"
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "timesheetUI.Absence.AbsenceModifyPastAlert"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c("v-data-table", {
                            staticClass: "absence-modify-table",
                            attrs: {
                              headers: _vm.headersConfirmation,
                              items: _vm.tableDataConfirmation,
                              dense: "",
                              "sort-by": "StartDate",
                              "hide-default-footer":
                                _vm.tableDataConfirmation.length <= 10,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "py-4 d-flex justify-space-between" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight--600",
                              attrs: { outlined: "", rounded: "" },
                              on: {
                                click: function ($event) {
                                  _vm.cancellationStatus = "default"
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("General.Btns.Close")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    depressed: "",
                                    rounded: "",
                                    color: "primary",
                                    disabled: _vm.loading,
                                  },
                                  on: { click: _vm.cancelAbsenceRequest },
                                },
                                [
                                  _vm.loading
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center mr-2",
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              size: "20",
                                              width: "2",
                                              color: "grey",
                                              indeterminate: "",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "loading-label" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "timesheetUI.Absence.SendingRequest"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "timesheetUI.Btns.BtnBookAbsenceCancelConfirm"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }