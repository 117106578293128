var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c("div", [
        !_vm.agencyData.isPercentageAgency
          ? _c("span", [_vm._v(_vm._s(_vm.hoursTotal.hours))])
          : _vm.agencyData.isPercentageAgency && _vm.mixedTimeEntry
          ? _c("span", [
              _vm._v(_vm._s(_vm.hoursTotal.hours)),
              _vm.mixedTimeEntry ? _c("span", [_vm._v("h")]) : _vm._e(),
            ])
          : _c("span", [_vm._v(_vm._s(_vm.hoursTotal.percent))]),
      ]),
      !_vm.disableTooltip
        ? _c("timesheet-tooltip-v2", {
            attrs: { top: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "trigger",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            "x-small": "",
                            color: "error",
                            "aria-label": `warning message`,
                            "aria-describedby": "dailyHoursTotalMessage",
                          },
                        },
                        [
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            attrs: { id: "dailyHoursTotalMessage" },
                            domProps: {
                              innerHTML: _vm._s(_vm.getTooltipMessage),
                            },
                          }),
                          _c("v-icon", [_vm._v("mdi-information-outline")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { attrs: { id: "dailyHoursTotal" } }, [
                        _vm.isTimeSaved
                          ? _c("div", { staticClass: "pa-2" }, [
                              _c(
                                "div",
                                [
                                  !_vm.agencyData.isPercentageAgency
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.timesheetStatusStats.approved
                                                .hours
                                            ) +
                                            "h "
                                        ),
                                      ]
                                    : _vm.agencyData.isPercentageAgency &&
                                      _vm.mixedTimeEntry
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.timesheetStatusStats.approved
                                              .hours
                                          )
                                        ),
                                        _vm.mixedTimeEntry
                                          ? _c("span", [_vm._v("h")])
                                          : _vm._e(),
                                      ])
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.timesheetStatusStats.approved
                                                .percent
                                            ) +
                                            "% "
                                        ),
                                      ],
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("timesheetUI.Status.Approved")
                                      ) +
                                      " "
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "div",
                                [
                                  !_vm.agencyData.isPercentageAgency
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.timesheetStatusStats.rejected
                                                .hours
                                            ) +
                                            "h "
                                        ),
                                      ]
                                    : _vm.agencyData.isPercentageAgency &&
                                      _vm.mixedTimeEntry
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.timesheetStatusStats.rejected
                                              .hours
                                          )
                                        ),
                                        _vm.mixedTimeEntry
                                          ? _c("span", [_vm._v("h")])
                                          : _vm._e(),
                                      ])
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.timesheetStatusStats.rejected
                                                .percent
                                            ) +
                                            "% "
                                        ),
                                      ],
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("timesheetUI.Status.Rejected")
                                      ) +
                                      " "
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "div",
                                [
                                  !_vm.agencyData.isPercentageAgency
                                    ? [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.timesheetStatusStats.submitted
                                                .hours
                                            ) +
                                            "h "
                                        ),
                                      ]
                                    : _vm.agencyData.isPercentageAgency &&
                                      _vm.mixedTimeEntry
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.timesheetStatusStats.submitted
                                              .hours
                                          )
                                        ),
                                        _vm.mixedTimeEntry
                                          ? _c("span", [_vm._v("h")])
                                          : _vm._e(),
                                      ])
                                    : [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.timesheetStatusStats.submitted
                                                .percent
                                            ) +
                                            "% "
                                        ),
                                      ],
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("timesheetUI.Status.Submitted")
                                      ) +
                                      " "
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm.getStatusMessage
                          ? _c("div", {
                              staticClass: "pa-2",
                              domProps: {
                                innerHTML: _vm._s(_vm.getStatusMessage),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4114093203
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }