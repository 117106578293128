<template>
  <div>
    <v-dialog
      v-model="absenceFormModifyDialog"
      width="800"
      :persistent="loading"
    >
      <FocusLock returnFocus>
        <v-card
          class="relative d-flex flex-column"
          v-if="cancellationStatus === 'default'"
          min-height="500px"
        >
          <v-card-title class="justify-space-between py-5">
            <h2 class="font-weight--600 text-h2">
              {{ $t('timesheetUI.Absence.AbsenceModifyTitle') }}
            </h2>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="flex-grow-1">
            <h3 class="font-weight--900 my-6 d-flex">
              {{ $t('timesheetUI.Absence.AbsenceCollapsedTitle') }} ({{
                dateRange
              }})
            </h3>

            <v-data-table
              class="absence-modify-table"
              :headers="headers"
              :items="tableData"
              dense
              sort-by="StartDate"
              :hide-default-footer="tableData.length <= 10"
              item-key="AbsenceRequestID"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  @click="cancelAbsenceValidate(item)"
                  small
                  depressed
                  rounded
                  v-if="
                    ![
                      absenceStatusMap.cancelled,
                      absenceStatusMap.rejected,
                      absenceStatusMap.cancellationPending,
                      absenceStatusMap.cancellationApproved,
                    ].includes(item.status)
                  "
                >
                  {{ $t('timesheetUI.Btns.BtnBookAbsenceCancel') }}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4 justify-end">
            <v-btn
              outlined
              rounded
              @click="closeModal"
              class="font-weight--600"
            >
              {{ $t('General.Btns.Close') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          class="relative d-flex flex-column"
          v-if="cancellationStatus !== 'default'"
          min-height="500px"
        >
          <v-card-title class="justify-space-between py-5">
            <h2 class="font-weight--600 text-h2">
              <span v-if="cancellationStatus === 'ongoing'">
                {{ $t('timesheetUI.Absence.AbsenceModifyTitleOngoing') }}
              </span>

              <span v-else>
                {{ $t('timesheetUI.Absence.AbsenceModifyTitleCancel') }}
              </span>
            </h2>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="flex-grow-1">
            <h3 class="font-weight--900 my-8 d-flex">
              <span v-if="cancellationStatus === 'ongoing'">
                {{ $t('timesheetUI.Absence.AbsenceModifyOngoingAlert') }}
              </span>

              <span v-if="cancellationStatus === 'future'">
                {{ $t('timesheetUI.Absence.AbsenceModifyFutureAlert') }}
              </span>

              <span v-if="cancellationStatus === 'past'">
                {{ $t('timesheetUI.Absence.AbsenceModifyPastAlert') }}
              </span>
            </h3>

            <v-data-table
              class="absence-modify-table"
              :headers="headersConfirmation"
              :items="tableDataConfirmation"
              dense
              sort-by="StartDate"
              :hide-default-footer="tableDataConfirmation.length <= 10"
            ></v-data-table>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions class="py-4 d-flex justify-space-between">
            <v-btn
              outlined
              rounded
              @click="cancellationStatus = 'default'"
              class="font-weight--600"
            >
              {{ $t('General.Btns.Close') }}
            </v-btn>

            <div class="d-flex align-center">
              <v-btn
                depressed
                rounded
                color="primary"
                @click="cancelAbsenceRequest"
                :disabled="loading"
              >
                <div class="d-flex align-center mr-2" v-if="loading">
                  <v-progress-circular
                    size="20"
                    width="2"
                    color="grey"
                    class="mr-2"
                    indeterminate
                  ></v-progress-circular>
                  <div class="loading-label">
                    {{ $t('timesheetUI.Absence.SendingRequest') }}
                  </div>
                </div>
                <span v-else>
                  {{ $t('timesheetUI.Btns.BtnBookAbsenceCancelConfirm') }}
                </span>
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </FocusLock>
    </v-dialog>
  </div>
</template>

<script>
import { formatDateFrontend } from '@Shared/filters'
import { mapActions, mapState, mapGetters } from 'vuex'
import moment from 'moment'
import FocusLock from 'vue-focus-lock'

export default {
  components: {
    FocusLock,
  },
  data() {
    return {
      absenceFormModifyDialog: false,
      headers: [
        {
          text: 'Leave type',
          value: 'leaveType',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Leave start',
          value: 'startDate',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Leave end',
          value: 'endDate',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Status',
          value: 'statusName',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '150px',
        },
      ],
      headersConfirmation: [
        {
          text: 'Leave type',
          value: 'leaveType',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Leave start',
          value: 'startDate',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Leave end',
          value: 'endDate',
          sortable: false,
          width: '150px',
        },
        {
          text: 'Status',
          value: 'statusName',
          sortable: false,
          width: '150px',
        },
      ],
      cancellationStatus: 'default',
      tableDataConfirmation: [],
      loading: false,
    }
  },
  watch: {
    absenceFormModifyDialog() {
      this.cancellationStatus = 'default'
    },
    customModifyData() {
      if (this.customModifyData.length) {
        this.absenceFormModifyDialog = true
      }
    },
  },
  computed: {
    ...mapState('storeAbsenceTracker', [
      'absenceData',
      'customModifyData',
      'year',
      'absenceStatusNameMap',
      'absenceStatusMap',
    ]),
    ...mapState('storeTimesheets', ['calendar']),
    ...mapGetters('storeTimesheets', ['selectedDate']),
    dateRange() {
      const start = this.selectedDate.startOfWeekFrontend
      const end = this.selectedDate.endOfWeekFrontend

      return `${start} - ${end}`
    },
    tableData() {
      if (this.customModifyData.length) {
        const startOfWeek = this.selectedDate.startOfWeek
        const endOfWeek = this.selectedDate.endOfWeek
        const mergedItems = []
        let customModifyDataCopy = JSON.parse(
          JSON.stringify(this.customModifyData)
        )

        const inRange = customModifyDataCopy.filter((b) => {
          b.startDate = b.rangeId ? b.rangeStartDate : b.absenceDate
          b.endDate = b.rangeId ? b.rangeEndDate : b.absenceDate
          if (
            (moment(b.endDate).isSameOrAfter(startOfWeek) &&
              moment(b.startDate).isSameOrBefore(endOfWeek)) ||
            this.customModifyData.calendarData
          ) {
            b.startDateBackend = b.startDate
            b.endDateBackend = b.endDate

            const formatStartDate = formatDateFrontend(b.startDate)
            const formatEndDate = formatDateFrontend(b.endDate)

            b.startDate = formatStartDate
            b.endDate = formatEndDate
            b.statusName = this.absenceStatusNameMap[b.status]

            return b
          }
        })

        if (inRange.length > 0) {
          mergedItems.push(...inRange)
        }

        return mergedItems
      }

      return []
    },
  },
  methods: {
    ...mapActions('storeAbsenceTracker', [
      'cancelUnapprovedAbsence',
      'cancelApprovedAbsence',
    ]),
    closeModal() {
      this.absenceFormModifyDialog = false
    },
    cancelAbsenceValidate(item) {
      const obj = JSON.parse(JSON.stringify(item))
      this.tableDataConfirmation = [obj]

      if (obj.status === 'Awaiting Approval') {
        this.cancellationStatus = 'cancel'
      } else {
        if (
          moment(obj.startDateBackend).isSameOrBefore(moment(), 'day') &&
          moment(obj.endDateBackend).isSameOrAfter(moment(), 'day')
        ) {
          this.cancellationStatus = 'ongoing'
        } else {
          if (
            moment(obj.startDateBackend).isSameOrBefore(moment(), 'day') &&
            moment(obj.endDateBackend).isSameOrBefore(moment(), 'day')
          ) {
            this.cancellationStatus = 'past'
          } else {
            if (moment(obj.startDateBackend).isAfter(moment(), 'day')) {
              this.cancellationStatus = 'future'
            } else {
              this.cancellationStatus = 'cancel'
            }
          }
        }
      }
    },
    cancelAbsenceRequest() {
      this.loading = true
      const toCancel = this.tableDataConfirmation[0]
      const absenceToCancelParam = {
        absenceIds: [toCancel.id],
        rangeIds: toCancel.rangeId ? [toCancel.rangeId] : null,
      }

      switch (toCancel.status) {
        case 2:
          this.cancelUnapprovedAbsence(absenceToCancelParam)
            .then(() => {
              this.absenceFormModifyDialog = false
            })
            .finally(() => {
              this.loading = false
            })
          break
        case 1:
        case 3:
        case 8:
          this.cancelApprovedAbsence(absenceToCancelParam)
            .then(() => {
              this.absenceFormModifyDialog = false
            })
            .finally(() => {
              this.loading = false
            })
          break
        default:
          ''
      }
    },
  },
}
</script>

<style lang="scss">
.absence-modify-table {
  th,
  td {
    padding: 12px 0 !important;
    border-bottom: none !important;
  }

  th {
    font-size: 16px !important;
    font-weight: 900;
  }
}
</style>
