<template>
  <div class="time-entry-form">
    <div v-if="!loadingFeatures && timesheetDetailFeatures">
      <div v-if="!validForEntry">
        <v-alert
          dense
          type="error"
          icon="mdi-alert-circle-outline"
          class="v-alert--custom"
        >
          <span
            class="font-color--body"
            v-if="
              timesheetDetailFeatures &&
              timesheetDetailFeatures.activity.required
            "
            >{{ $t('timesheetUI.SearchJobs.NoStudioActivities') }}</span
          >
          <span
            class="font-color--body"
            v-if="
              timesheetDetailFeatures &&
              timesheetDetailFeatures.workcode.showAbsenceErrorMessage
            "
            >{{ $t('timesheetUI.SearchJobs.NotEligible') }}</span
          >
          <span class="font-color--body" v-else>{{
            $t('timesheetUI.SearchJobs.NoJobs')
          }}</span>
        </v-alert>
      </div>
      <timesheet-entry-detail-review v-else-if="isTimesheetBeingReviewed" />
      <div v-else>
        <CopyToRestOfWeek />
        <div
          v-if="
            !timesheetDetailFeatures ||
            (!timesheetDetailFeatures.activity.required &&
              !timesheetDetailFeatures.task.required)
          "
        >
          <timesheet-entry-detail-location v-if="agencyData.isLocationActive" />

          <timesheet-entry-detail-default v-else />
        </div>
        <div v-else>
          <timesheet-entry-detail-studio
            v-if="timesheetDetailFeatures.activity.required"
          />

          <timesheet-entry-detail-task
            v-else-if="timesheetDetailFeatures.task.required"
          />
          <timesheet-entry-detail-default v-else />
        </div>

        <div v-if="timesheetDetail && timesheetDetail.id">
          <v-row>
            <v-col v-if="!isTemporaryExternalEmployee">
              <div :class="`font-size--14 font-weight--600 mb-1}`">
                {{
                  $t(
                    'timesheetUI.EntryFormLabels.TimesheetEntryAdditionalInformation'
                  )
                }}
              </div>
              <div v-if="!isEnabledSelfCorrection">
                {{
                  timesheetStatusDescriptions[
                    timesheetDetail.timesheetStatus.toLowerCase()
                  ]
                }}
              </div>
              <div v-else>{{ getSelfCorrectionRemainingDays }}</div>

              <div v-if="timesheetDetail.isWipCorrected" class="mb-1">
                {{ $t('timesheetUI.EntryFormLabels.WIPCorrectedMessage') }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12">
              <div class="mb-1 timesheet-details-label">
                {{ $t('timesheetUI.InfoPanel.LabelSubmittedBy') }}
              </div>
              <div
                v-if="
                  timesheetDetail.submittedByAltairCode &&
                  timesheetDetail.timesheetStatus.toLowerCase() !==
                    'unsubmitted'
                "
              >
                {{ timesheetDetail.submittedByName }} ({{
                  timesheetDetail.submittedByAltairCode
                }})
              </div>
              <div v-else>-</div>
            </v-col>
            <v-col
              v-if="!isTemporaryExternalEmployee"
              :cols="isTimeEntryDrawerExpanded ? 6 : 12"
            >
              <div class="mb-1 timesheet-details-label">
                {{ $t('timesheetUI.InfoPanel.LabelApprover') }}
              </div>
              <div v-if="timesheetDetail.assignedApproverName">
                {{ timesheetDetail.assignedApproverName }} ({{
                  timesheetDetail.assignedApproverAltairCode
                }})
              </div>
              <div v-else>-</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12">
              <div class="mb-1 timesheet-details-label">
                {{ $t('timesheetUI.InfoPanel.LabelSubmittedOn') }}
              </div>
              <div
                v-if="
                  timesheetDetail.submittedDate &&
                  timesheetDetail.timesheetStatus.toLowerCase() !==
                    'unsubmitted'
                "
              >
                {{ timesheetDetail.submittedDate | formatDateMonthAsWord }}
              </div>
              <div v-else>-</div>
            </v-col>
            <v-col
              v-if="!isTemporaryExternalEmployee"
              :cols="isTimeEntryDrawerExpanded ? 6 : 12"
            >
              <div
                v-if="
                  timesheetDetail.timesheetStatus.toLowerCase() === 'rejected'
                "
              >
                <div class="mb-1 timesheet-details-label">
                  {{ $t('timesheetUI.InfoPanel.LabelRejectedOn') }}
                </div>
                <div v-if="timesheetDetail.approvalDate">
                  {{ timesheetDetail.approvalDate | formatDateMonthAsWord }}
                </div>
                <div v-else>-</div>
              </div>
              <div v-else>
                <div class="mb-1 timesheet-details-label">
                  {{ $t('timesheetUI.InfoPanel.LabelApprovedOn') }}
                </div>
                <div
                  v-if="
                    timesheetDetail.approvalDate &&
                    timesheetDetail.timesheetStatus.toLowerCase() !==
                      'unsubmitted'
                  "
                >
                  {{ timesheetDetail.approvalDate | formatDateMonthAsWord }}
                </div>
                <div v-else>-</div>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="timesheetDetail.rejectionComment">
            <v-col v-if="!isTemporaryExternalEmployee">
              <div class="mb-1 font-size--13 font-weight--600">
                {{ $t('timesheetUI.InfoPanel.Comment') }}
              </div>
              <div>{{ timesheetDetail.rejectionComment }}</div>
            </v-col>
          </v-row>
        </div>
        <div
          v-if="
            timesheetDetailFeatures.location.required &&
            timesheetDetail.timesheetStatus !== 'Approved'
          "
          class="px-4"
        >
          <v-row>
            <v-col md="12">
              <div
                v-if="agencyData.isPsAgency"
                class="time-entry__info-message error--text"
              >
                {{ $t('timesheetUI.InfoPanel.locationUpdate.header') }}
                <ul>
                  <li>
                    {{ $t('timesheetUI.InfoPanel.locationUpdate.list1') }}
                  </li>
                  <li>
                    {{ $t('timesheetUI.InfoPanel.locationUpdate.list2') }}
                  </li>
                </ul>
                <br />
              </div>
              <div class="time-entry__info-message">
                {{ $t('timesheetUI.ValidationMessages.LocationMessage') }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import TimesheetEntryDetailDefault from './forms/TimesheetEntryDetailDefault.vue'
import TimesheetEntryDetailStudio from './forms/TimesheetEntryDetailStudio.vue'
import TimesheetEntryDetailTask from './forms/TimesheetEntryDetailTask.vue'
import TimesheetEntryDetailLocation from './forms/TimesheetEntryDetailLocation.vue'
import TimesheetEntryDetailReview from './TimesheetEntryDetailReview.vue'
import moment from 'moment'
import i18n from '@Shared/i18n'
import { isEmpty } from 'lodash'
import CopyToRestOfWeek from '../copy-to-rest-of-week'

export default {
  name: 'TimesheetEntryDetail',
  components: {
    TimesheetEntryDetailDefault,
    TimesheetEntryDetailLocation,
    TimesheetEntryDetailStudio,
    TimesheetEntryDetailTask,
    TimesheetEntryDetailReview,
    CopyToRestOfWeek,
  },
  data() {
    return {
      timesheetStatusDescriptions: i18n.t(
        'timesheetUI.EntryFormLabels.timesheetStatusDescriptions',
        window.CULTURE
      ),
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetailFeatures',
      'timesheetDetail',
      'agencyData',
      'isTimesheetBeingReviewed',
      'loadingFeatures',
      'isTimeEntryDrawerExpanded',
    ]),
    ...mapGetters('storeTimesheets', [
      'validForEntry',
      'isEnabledSelfCorrection',
      'isTemporaryExternalEmployee',
      'getRejectedCorrectedTimesheets',
    ]),
    getSelfCorrectionRemainingDays() {
      if (
        // eslint-disable-next-line no-prototype-builtins
        (!this.agencyData.hasOwnProperty('daysValidationId') ||
          this.agencyData.daysValidationId == 0 ||
          this.agencyData.daysValidationId == null) &&
        this.timesheetDetail.timesheetStatus === 'Approved'
      ) {
        this.setIsCorrectionValid(false)
        return this.timesheetStatusDescriptions.noConfigureSelfCorrection
      }
      if (
        this.timesheetDetail.timesheetStatus !== 'Approved' &&
        !this.timesheetDetail.isSelfCorrected
      ) {
        return this.timesheetStatusDescriptions[
          this.timesheetDetail.timesheetStatus.toLowerCase()
        ]
      }
      const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'

      const lastDateofCorrection = moment(
        moment(this.timesheetDetail.lastDateofCorrection.split('T')[0])
          .locale(locale)
          .format('dddd DD MMM YYYY')
      )

      const today = moment(moment().locale(locale).format('dddd DD MMM YYYY'))

      let numOfDays = lastDateofCorrection.diff(today, 'days')

      if (
        this.timesheetDetail.isSelfCorrected ||
        this.timesheetDetail.isWipCorrected
      ) {
        this.setIsCorrectionValid(false)
        return this.timesheetStatusDescriptions.canNotCorrect
      }

      if (!isEmpty(this.getRejectedCorrectedTimesheets)) {
        this.setIsCorrectionValid(false)
        return this.timesheetStatusDescriptions.rejectedSelfCorrection
      }

      if (moment(lastDateofCorrection).isSameOrAfter(today)) {
        if (numOfDays == 0) numOfDays = 1

        this.setIsCorrectionValid(true)
        return this.timesheetStatusDescriptions.approvedEnabledSelfCorrection.replace(
          '${day}',
          numOfDays
        )
      } else {
        this.setIsCorrectionValid(false)
        return this.timesheetStatusDescriptions.canNotCorrect
      }
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', ['setIsCorrectionValid']),
  },
}
</script>

<style lang="scss" scoped>
.timesheet-status {
  color: $primary;

  &--approved {
    color: $approved;
  }

  &--rejected {
    color: #b00c5b;
  }
}
.timesheet-details-label {
  color: #757575;
}

.time-entry__info-message {
  color: $primary;

  .click-here__decoration {
    color: $textColor;
    cursor: pointer;

    &:focus {
      border: 2px solid $primary;
      border-radius: 4px;
    }
  }
}
</style>

<style lang="scss" scoped>
.time-entry-form::v-deep {
  .v-messages__message {
    position: relative;
    width: auto;
  }

  .v-text-field__details {
    padding: 0 5px !important;
    overflow: visible !important;
  }
  .v-label {
    font-weight: 600;
  }
  .v-input {
    margin-top: 6px;
    .v-label {
      font-weight: normal;
    }
  }
  .v-input--selection-controls__input .v-icon {
    color: $border-color;
  }
}
</style>
