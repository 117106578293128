var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c("div", {
        staticClass: "sr-only",
        attrs: { "aria-live": "assertive" },
        domProps: { textContent: _vm._s(_vm.liveRegionText) },
      }),
      _c("div", { ref: "errorContainer" }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              staticClass: "pb-0",
              attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
            },
            [
              _vm.timesheetDetailFeatures.percentageOrDuration.data
                .isPercentageAgency
                ? _c(
                    "div",
                    [
                      _c("v-label", { attrs: { for: "durationInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|durationValidation",
                          name: "DurationField",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-text-field", {
                                    ref: "timeEntryInput",
                                    attrs: {
                                      id: "durationInput",
                                      "aria-required": "true",
                                      outlined: "",
                                      dense: "",
                                      autofocus: "",
                                      placeholder: "Duration",
                                      "error-messages": errors,
                                      "aria-describedby": "durationInputError",
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.handleDurationFocus(errors)
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "message",
                                          fn: function ({ message }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  attrs: {
                                                    "aria-live": "polite",
                                                    id: "durationInputError",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(message) + " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.timesheetDetail
                                          .durationPercentageValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.timesheetDetail,
                                          "durationPercentageValue",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "timesheetDetail.durationPercentageValue",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          754248197
                        ),
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-label", { attrs: { for: "durationInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryDuration"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|durationValidation",
                          name: "DurationField",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ errors }) {
                              return [
                                _c("v-text-field", {
                                  ref: "timeEntryInput",
                                  attrs: {
                                    id: "durationInput",
                                    "aria-required": "true",
                                    outlined: "",
                                    dense: "",
                                    autofocus: "",
                                    placeholder: "Duration",
                                    "error-messages": errors,
                                    "aria-describedby": "durationInputError",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.handleDurationFocus(errors)
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "message",
                                        fn: function ({ message }) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                attrs: {
                                                  "aria-live": "polite",
                                                  id: "durationInputError",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(message) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: _vm.timesheetDetail.durationValue,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.timesheetDetail,
                                        "durationValue",
                                        $$v
                                      )
                                    },
                                    expression: "timesheetDetail.durationValue",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
              _vm.firstAvailableContract.country === "US" &&
              _vm.timesheetDetailFeatures.location.required
                ? _c(
                    "div",
                    {
                      staticClass: "attached-dropdown",
                      attrs: { id: "locationInput-container" },
                    },
                    [
                      _c("v-label", { attrs: { for: "locationInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: { rules: "required", name: "Location" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-autocomplete", {
                                    ref: "locationInput",
                                    attrs: {
                                      role: "combobox",
                                      id: "locationInput",
                                      attach: "#locationInput-container",
                                      "aria-required": "true",
                                      "menu-props": {
                                        offsetY: true,
                                        nudgeBottom: 28,
                                        contentClass: "location-list-indented",
                                      },
                                      outlined: "",
                                      dense: "",
                                      filter: _vm.customFilter,
                                      items:
                                        _vm.timesheetDetailFeatures.location
                                          .data.locations,
                                      "item-text": "label",
                                      "item-value": "value",
                                      placeholder: _vm.$t(
                                        "timesheetUI.EntryFormLabels.TimesheetEntryLabelLocations"
                                      ),
                                      "error-messages": errors,
                                      clearable: "",
                                    },
                                    on: {
                                      change: _vm.handleLocation,
                                      keydown: _vm.arrowKeyHandler,
                                      focus: function ($event) {
                                        return _vm.announceError(errors)
                                      },
                                    },
                                    model: {
                                      value: _vm.locationInLocal,
                                      callback: function ($$v) {
                                        _vm.locationInLocal = $$v
                                      },
                                      expression: "locationInLocal",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1236004647
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.firstAvailableContract.country === "US" &&
              _vm.timesheetDetailFeatures.location.required
                ? _c("timesheet-manage-preference-location-select", {
                    attrs: {
                      "should-update-location": _vm.updateLocationSettings,
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "attached-dropdown",
                  attrs: { id: "activityInput-container" },
                },
                [
                  _c("v-label", { attrs: { for: "activityInput" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetStudioActivity"
                        )
                      ) + " "
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v("*")]),
                  ]),
                  _c("validation-provider", {
                    attrs: { rules: "required", name: "Studio Activity" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-autocomplete", {
                              ref: "activityInput",
                              attrs: {
                                role: "listbox",
                                "aria-required": "true",
                                id: "activityInput",
                                attach: "#activityInput-container",
                                "menu-props": {
                                  offsetY: true,
                                  nudgeBottom: 28,
                                  allowOverflow: true,
                                },
                                outlined: "",
                                dense: "",
                                items:
                                  _vm.timesheetDetailFeatures.activity.data
                                    .activities,
                                "error-messages": errors,
                                "item-text": "label",
                                "item-value": "value",
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetStudioActivity"
                                ),
                                clearable: "",
                              },
                              on: {
                                change: _vm.doPSLogicActivity,
                                keydown: _vm.arrowKeyHandler,
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              model: {
                                value: _vm.timesheetDetail.activity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.timesheetDetail, "activity", $$v)
                                },
                                expression: "timesheetDetail.activity",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              class: ["pb-0", { "pt-0": !_vm.isTimeEntryDrawerExpanded }],
              attrs: { cols: _vm.isTimeEntryDrawerExpanded ? 6 : 12 },
            },
            [
              _c(
                "div",
                {
                  staticClass: "attached-dropdown",
                  attrs: { id: "billableIndicator-container" },
                },
                [
                  _c("v-label", { attrs: { for: "billableIndicator" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryLabelBI"
                        )
                      ) + " "
                    ),
                    _c("span", { staticClass: "required" }, [_vm._v("*")]),
                  ]),
                  _c("validation-provider", {
                    attrs: { rules: "required", name: "Billable Indicator" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-combobox", {
                              ref: "billableIndicator",
                              attrs: {
                                role: "listbox",
                                id: "billableIndicator",
                                "aria-required": "true",
                                outlined: "",
                                attach: "#billableIndicator-container",
                                dense: "",
                                items: _vm.timesheetDetailBillableIndicators,
                                "item-text": "label",
                                placeholder: "Billable Indicator",
                                "menu-props": {
                                  offsetY: true,
                                  nudgeBottom: 28,
                                },
                                "error-messages": errors,
                                loading: _vm.loadingBI,
                                "return-object": "",
                                disabled:
                                  _vm.isTimesheetApproved ||
                                  _vm.timesheetDetailBillableIndicators
                                    .length <= 1 ||
                                  _vm.timesheetDetail.disablePPMSelect ||
                                  _vm.disablePPMWorkcode,
                              },
                              on: {
                                keydown: _vm.arrowKeyHandler,
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              model: {
                                value: _vm.timesheetDetail.billableIndicator,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetail,
                                    "billableIndicator",
                                    $$v
                                  )
                                },
                                expression: "timesheetDetail.billableIndicator",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "attached-dropdown",
                  attrs: { id: "productReferenceInput-container" },
                },
                [
                  _c("v-label", { attrs: { for: "productReferenceInput" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryProductReferenceLabel"
                        )
                      ) + " "
                    ),
                    _vm.timesheetDetail.productQuantity
                      ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                      : _vm._e(),
                  ]),
                  _c("validation-provider", {
                    attrs: {
                      rules: `${
                        _vm.timesheetDetail.productQuantity ? "required" : ""
                      }`,
                      name: "Product Reference",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-autocomplete", {
                              ref: "productReferenceInput",
                              attrs: {
                                role: "listbox",
                                id: "productReferenceInput",
                                "aria-required": "true",
                                outlined: "",
                                attach: "#productReferenceInput-container",
                                dense: "",
                                "menu-props": {
                                  nudgeBottom: 28,
                                  offsetY: true,
                                },
                                items:
                                  _vm.timesheetDetailFeatures.productRef.data
                                    ?.productRefs,
                                "error-messages": errors,
                                "item-text": "label",
                                "item-value": "value",
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryProductReferenceLabel"
                                ),
                                clearable: "",
                              },
                              on: {
                                "click:clear": function ($event) {
                                  _vm.timesheetDetail.productQuantity = null
                                },
                                keydown: _vm.arrowKeyHandler,
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              model: {
                                value: _vm.timesheetDetail.productReference,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetail,
                                    "productReference",
                                    $$v
                                  )
                                },
                                expression: "timesheetDetail.productReference",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm.timesheetDetailFeatures.location.required &&
              _vm.agencyData.isWFHActive
                ? _c(
                    "div",
                    {
                      staticClass: "attached-dropdown",
                      attrs: { id: "wfhInput-container" },
                    },
                    [
                      _c("v-label", { attrs: { for: "wfhInput" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel"
                            )
                          ) + " "
                        ),
                        _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      ]),
                      _c("validation-provider", {
                        attrs: { rules: "required", name: "Work From Home" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c("v-select", {
                                    ref: "wfhInput",
                                    attrs: {
                                      role: "listbox",
                                      id: "wfhInput",
                                      attach: "#wfhInput-container",
                                      "aria-required": "true",
                                      outlined: "",
                                      dense: "",
                                      "menu-props": {
                                        nudgeBottom: 28,
                                        offsetY: true,
                                        bottom: true,
                                      },
                                      items: _vm.wfhOptions,
                                      "item-text": "label",
                                      "item-value": "value",
                                      placeholder: _vm.$t(
                                        "timesheetUI.EntryFormLabels.TimesheetEntryWfhLabel"
                                      ),
                                      "error-messages": errors,
                                    },
                                    on: {
                                      keyup: [
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "down",
                                              40,
                                              $event.key,
                                              ["Down", "ArrowDown"]
                                            )
                                          )
                                            return null
                                          return $event.target.click()
                                        },
                                        function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "up",
                                              38,
                                              $event.key,
                                              ["Up", "ArrowUp"]
                                            )
                                          )
                                            return null
                                          return $event.target.click()
                                        },
                                      ],
                                      focus: function ($event) {
                                        return _vm.announceError(errors)
                                      },
                                      change: _vm.handlewFHChange,
                                    },
                                    model: {
                                      value: _vm.timesheetDetail.wfh,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.timesheetDetail,
                                          "wfh",
                                          $$v
                                        )
                                      },
                                      expression: "timesheetDetail.wfh",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1677575854
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.timesheetDetailFeatures.location.required &&
              _vm.agencyData.isWFHActive
                ? _c("timesheet-manage-preference-wfh-select", {
                    attrs: { "should-update-wfh": _vm.updateWFHSettings },
                  })
                : _vm._e(),
              _c(
                "div",
                [
                  _c("v-label", { attrs: { for: "productQuantityInput" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryQuantityLabel"
                        )
                      ) + " "
                    ),
                    _vm.timesheetDetail.productReference
                      ? _c("span", { staticClass: "required" }, [_vm._v("*")])
                      : _vm._e(),
                  ]),
                  _c("validation-provider", {
                    attrs: {
                      rules: `${
                        _vm.timesheetDetail.productReference ? "required|" : ""
                      }quantityValidation`,
                      name: "Product Quantity",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                id: "productQuantityInput",
                                "aria-required": "true",
                                outlined: "",
                                dense: "",
                                "error-messages": errors,
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryQuantityLabel"
                                ),
                                maxLength: "15",
                                "aria-describedby": "productQuantityInputError",
                              },
                              on: {
                                input: _vm.checkProductRef,
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "message",
                                    fn: function ({ message }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              "aria-live": "polite",
                                              id: "productQuantityInputError",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(message) + " ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.timesheetDetail.productQuantity,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.timesheetDetail,
                                    "productQuantity",
                                    $$v
                                  )
                                },
                                expression: "timesheetDetail.productQuantity",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("v-label", { attrs: { for: "commentInput" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelComments"
                        )
                      )
                    ),
                  ]),
                  _c("validation-provider", {
                    attrs: { rules: "maxComment", name: "Comment" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ errors }) {
                          return [
                            _c("v-text-field", {
                              attrs: {
                                id: "commentInput",
                                outlined: "",
                                dense: "",
                                placeholder: _vm.$t(
                                  "timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelCommentsPlaceholder"
                                ),
                                "error-messages": errors,
                                counter: "",
                                maxlength: "125",
                                "aria-describedby": "commentInputError",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.announceError(errors)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "message",
                                    fn: function ({ message }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            attrs: {
                                              "aria-live": "polite",
                                              id: "commentInputError",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(message) + " ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.timesheetDetail.comment,
                                callback: function ($$v) {
                                  _vm.$set(_vm.timesheetDetail, "comment", $$v)
                                },
                                expression: "timesheetDetail.comment",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }