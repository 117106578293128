var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("GridRowWithPageOffset", {
    scopedSlots: _vm._u(
      [
        {
          key: "col1",
          fn: function () {
            return [
              _c("GridCell", [
                _c(
                  "div",
                  { staticClass: "font-size--12 font-weight--600 text-right" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("timesheetUI.Footer.FooterTotalLabel")) +
                        " " +
                        _vm._s(
                          _vm.$t(
                            _vm.agencyData.isPercentageAgency
                              ? "timesheetUI.HeaderCalendar.LabelPercentage"
                              : ""
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        _vm._l(_vm.calendar, function (day, i) {
          return {
            key: day.dayName.toLowerCase(),
            fn: function () {
              return [
                _c("GridCell", { key: day.dayName }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      day.isRequiredByUserContract
                        ? _c("DailyHoursGrid", {
                            attrs: {
                              day: day,
                              firstAvailableContract:
                                _vm.firstAvailableContract,
                              timesheetHoursPerDayComputed:
                                _vm.timesheetHoursPerDayComputed,
                              hoursTotalPerDay: _vm.hoursTotalPerDay,
                              getRemainingHours: _vm.getRemainingHours,
                              mixedTimeEntry: _vm.mixedTimeEntry,
                              IsPercentageByDay: _vm.IsPercentageByDay,
                              isThresholdError: _vm.isThresholdError,
                              tooltipsActive: _vm.tooltipsActive,
                              setTooltip: _vm.setTooltip,
                              hideTooltip: _vm.hideTooltip,
                              index: i,
                            },
                          })
                        : _c("span", [_vm._v("-")]),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          }
        }),
        {
          key: "total",
          fn: function () {
            return [
              _c("GridCell", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center" },
                  [
                    _c("DailyHoursTotal", {
                      attrs: {
                        disableTooltip: _vm.isPartTimeOrContractorEmployee(),
                        timesheetCanBeSubmitted: _vm.timesheetCanBeSubmitted,
                        hoursTotal: _vm.hoursTotal,
                        mixedTimeEntry: _vm.mixedTimeEntry,
                        timesheetHoursPerDayComputed:
                          _vm.timesheetHoursPerDayComputed,
                        timeSchduleComputed: _vm.timeSchduleComputed,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }