var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      class: [
        `v-btn--daily v-btn--${_vm.dayStatus.status}`,
        { disabled: false },
      ],
      attrs: {
        small: "",
        depressed: "",
        rounded: "",
        color: "primary",
        disabled:
          _vm.submittingTimesheets || _vm.statusNotEligibleToBeSubmitted,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.dayStatus.status === "unsubmitted" || _vm.dayStatus.status === "none"
        ? [_vm._v(" " + _vm._s(_vm.$t("timesheetUI.Btns.BtnSubmit")) + " ")]
        : [_vm._v(" " + _vm._s(_vm.$t("timesheetUI.Btns.BtnSubmitted")) + " ")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }