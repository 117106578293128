<template>
  <div>
    <GridRowWithoutPageOffset v-if="showDailyDailyActionRow" no-borders>
      <template #col1>
        <GridCell no-gutters>
          <div class="d-flex align-center flex-wrap">
            <div
              v-if="agencyData.enableAbsenceModule && absenceModule"
              class="mr-2"
            >
              <absence-form-create
                :selectedDate="selectedDate"
              ></absence-form-create>
            </div>

            <CopyJobsFromPreviousWeek
              v-if="isAnyJobPresentOnTimesheet"
              height="36"
              class="font-size--14"
            />
          </div>
        </GridCell>
      </template>
      <template v-for="(day, i) in calendar" v-slot:[day.dayName.toLowerCase()]>
        <template v-if="isAnyJobPresentOnTimesheet">
          <GridCell :key="i" no-gutters>
            <div v-if="!isHiddenJobsScreen" class="d-flex justify-center">
              <DailySubmitButton
                :aria-label="getAriaLabel(i, day)"
                :dayStatus="dailyStatus[i]"
                :submittingTimesheets="submittingTimesheets"
                @click="
                  () => {
                    dailyStatus[i].state === 'enabled' &&
                      timesheetSubmitDay(i, day)
                  }
                "
              />
            </div>
          </GridCell>
        </template>
      </template>
      <template #total v-if="isAnyJobPresentOnTimesheet">
        <GridCell no-gutters>
          <div class="d-flex justify-center">
            <v-btn
              small
              depressed
              rounded
              id="btn-submit"
              :class="[
                'v-btn--daily v-btn--daily-submit-all',
                { disabled: false },
              ]"
              color="primary"
              @click="timesheetSubmitWeek"
              :disabled="
                !(timesheetCanBeSubmitted && timesheetUnsubmittedAllDays) ||
                submittingTimesheets
              "
            >
              <span v-if="submittingTimesheets">
                {{ $t('timesheetUI.Btns.BtnsSaving') }}
              </span>
              <span v-else>
                <span v-if="$vuetify.breakpoint.lgAndUp">{{
                  $t('timesheetUI.Btns.BtnSubmitAll')
                }}</span>
                <span v-else>{{
                  $t('timesheetUI.MobileBtns.BtnSubmitAll')
                }}</span>
              </span>
            </v-btn>
          </div>
        </GridCell>
      </template>
    </GridRowWithoutPageOffset>

    <GridRowWithoutPageOffset
      v-if="showDailyWeeklyOrWeeklyWeeklyActionRow"
      no-borders
      class="py-2"
    >
      <div class="d-flex justify-between">
        <div class="d-flex flex-grow-1">
          <div
            v-if="agencyData.enableAbsenceModule && absenceModule"
            class="mr-2"
          >
            <absence-form-create
              :selectedDate="selectedDate"
            ></absence-form-create>
          </div>

          <CopyJobsFromPreviousWeek
            v-if="isAnyJobPresentOnTimesheet"
            height="36"
            class="font-size--14"
          />
        </div>

        <v-btn
          v-if="isAnyJobPresentOnTimesheet"
          depressed
          rounded
          id="btn-submit"
          :class="[
            'v-btn--custom-icon v-btn--long ',
            { 'v-btn--fullwidth': $vuetify.breakpoint.smAndDown },
            { disabled: false },
          ]"
          color="primary"
          @click="timesheetSubmitWeek"
          :disabled="
            !(timesheetCanBeSubmitted && timesheetUnsubmittedAllDays) ||
            submittingTimesheets
          "
        >
          <span v-if="submittingTimesheets">
            {{ $t('timesheetUI.Btns.BtnsSaving') }}
          </span>
          <span v-else>
            {{ $t('timesheetUI.Btns.BtnSubmitTimesheet') }}
          </span>
        </v-btn>
      </div>
    </GridRowWithoutPageOffset>

    <timesheet-dialog-self-consent
      @submitTimesheet="submitTimesheetDayOrWeek"
      :dialogTitle="selfConsentDialogTitle"
    ></timesheet-dialog-self-consent>
  </div>
</template>

<script>
// import GridFooter from './GridFooter.vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { uniqBy } from 'lodash'
import moment from 'moment'

import { EventBus } from '@Shared/EventBus'
import i18n from '@Shared/i18n'
import { clientMicroServicePost } from '@apis/clientService'
import {
  percisionRound,
  formatDateMonthAsWord,
  getTimesheets,
} from '@Shared/filters'

import GridRowWithoutPageOffset from '@Components/grid/GridRowWithoutPageOffset.vue'
import GridCell from '@Components/grid/GridCell.vue'
import AbsenceFormCreate from '../Absence/AbsenceFormCreate.vue'
import DailySubmitButton from './components/DailySubmitButton.vue'
import CopyJobsFromPreviousWeek from '../copy-jobs-from-previous-week'
import TimesheetDialogSelfConsent from '@Components/TimesheetDialogSelfConsent.vue'
import { validateTotalDurationPerDay } from '../../utils/timesheetHelper'

export default {
  name: 'FooterActionRow',
  components: {
    GridRowWithoutPageOffset,
    GridCell,
    AbsenceFormCreate,
    DailySubmitButton,
    CopyJobsFromPreviousWeek,
    TimesheetDialogSelfConsent,
  },
  props: [
    'tooltipsActive',
    'submittingTimesheets',
    'IsPercentageByDay',
    'updateSubmittingTimesheets',
  ],
  data() {
    return {
      submitTimesheetDayOrWeek: () => {},
      selfConsentDialogTitle: '',
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'userData',
      'agencyData',
      'timesheets',
      'firstAvailableContract',
      'altairCode',
      'calendar',
      'timesheetDialogVisibility',
      'absenceModule',
      'showSelfConsentSubmitDialog',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDate',
      'hoursTotalPerDay',
      'timesheetJobs',
      'isHiddenJobsScreen',
      'isAnyJobPresentOnTimesheet',
      'isAbsenceModuleEnabled',
      'isLocationActiveForThisWeek',
      'isLocationActiveForThisDay',
    ]),
    ...mapState('storeAbsenceTracker', [
      'absenceData',
      'loadingAbsenceData',
      'absenceBookingAllowed',
    ]),

    showActionRow() {
      return (
        this.isAnyJobPresentOnTimesheet ||
        // this.showTimesheetCopyBtn ||
        this.isAbsenceModuleEnabled
      )
    },

    showDailyDailyActionRow() {
      return this.agencyData.dwIndicator === '1' && this.showActionRow
    },

    showDailyWeeklyOrWeeklyWeeklyActionRow() {
      return (
        (this.agencyData.dwIndicator === '2' ||
          this.agencyData.dwIndicator === '3') &&
        this.showActionRow
      )
    },

    timesheetHoursPerDayComputed() {
      let total = 0
      let totalHoursFTPT = 0

      const contractHoursPerDay = this.calendar.map((day) => {
        const hours = day.requiredTimesheetHours
        if (day.isRequiredByUserContract && day.isWeekend === false) {
          total += hours
        }
        if (
          day.isRequiredByUserContract &&
          day.isWeekend === false &&
          !day.isPartTimeEmployee &&
          !day.externalEmployeeIndicator &&
          day.careerStageLevel !== 'Intern' &&
          day.employeeType !== 'H'
        ) {
          totalHoursFTPT += hours
        }

        return hours
      })

      const uniqDayArray = contractHoursPerDay
        .map((day, i) => {
          return {
            day: this.calendar[i].dayName,
            dayPrev: this.calendar[i > 0 ? i - 1 : 6].dayName,
            requiredHours: day,
          }
        })
        .slice(0, 5)

      let obj = {
        totalHours: total,
        totalHoursFTPT: totalHoursFTPT,
        hoursPerDay: contractHoursPerDay,
        allValueSame: contractHoursPerDay.filter((value) => value !== 0)[0],
        calendarType: 'normalTimesheet',
        uniqDays: uniqBy(uniqDayArray, 'requiredHours'),
      }

      if (this.agencyData.isAlternateCalendarAvailable) {
        if (contractHoursPerDay[4] === 5.5) {
          if (contractHoursPerDay.some((day) => day === 7)) {
            obj.calendarType = 'spanishLeavingSummerTimesheet'
          } else {
            obj.calendarType = 'spanishNormalTimesheet'
          }
        } else if (contractHoursPerDay.slice(0, 5).every((day) => day === 7)) {
          obj.calendarType = 'spanishSummerTimesheet'
        } else if (
          contractHoursPerDay[4] === 7 &&
          contractHoursPerDay.some((day) => day === 8 || day === 5.5)
        ) {
          obj.calendarType = 'spanishEnteringSummerTimesheet'
        }
      }

      return obj
    },
    timesheetUnsubmittedAllDays() {
      const daysUnsubmitted = this.timesheets.some((job) => {
        let durationValue = this.agencyData.isPercentageAgency
          ? job.durationPercentageValue
          : job.durationValue
        return job.timesheetStatus === 'Unsubmitted' && durationValue !== 0
      })

      return daysUnsubmitted
    },
    timesheetIdArrays() {
      let arrayObjs

      if (this.agencyData.dwIndicator === '1') {
        arrayObjs = {
          TimesheetIdList: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          },
          JobIdList: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          },
          UserJobs: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
          },
        }

        const daysToSubmitValues = []
        const daysHoursRequired = []
        this.calendar.forEach((day, i) => {
          const dayReported = day.date.split('T')[0]
          if (
            day.isRequiredByUserContract &&
            day.isWeekend === false &&
            !this.special(dayReported)
          ) {
            if (this.agencyData.isPercentageAgency) {
              daysToSubmitValues[i] = this.hoursTotalPerDay[i].percent
              daysHoursRequired[i] = 100
            } else {
              daysHoursRequired[i] =
                this.timesheetHoursPerDayComputed.hoursPerDay[i]
              daysToSubmitValues[i] = this.hoursTotalPerDay[i].hours
            }
          }
        })

        this.timesheets.forEach((ts) => {
          const i = moment(ts.reportedDate.split('T')[0]).isoWeekday() - 1
          let duration = daysToSubmitValues[i] >= daysHoursRequired[i]

          if (i > 4 && ts.durationPercentageValue > 0) {
            duration = true
          }

          if (
            !['Approved', 'Submitted', 'None', 'Rejected'].includes(
              ts.timesheetStatus
            ) &&
            (ts.durationValue !== 0 || ts.durationPercentageValue !== 0) &&
            duration
          ) {
            arrayObjs.TimesheetIdList[i].push(ts.id)
            arrayObjs.JobIdList[i].push(ts.job_ID)
            arrayObjs.UserJobs[i].push(ts.userJobId)
          }
        })
      } else {
        arrayObjs = {
          TimesheetIdList: [],
          JobIdList: [],
          UserJobs: [],
        }

        this.timesheets.forEach((ts) => {
          if (
            ts.timesheetStatus === 'Unsubmitted' &&
            (ts.durationValue !== 0 || ts.durationPercentageValue !== 0)
          ) {
            !arrayObjs.TimesheetIdList.includes(ts.Id) &&
              arrayObjs.TimesheetIdList.push(ts.id)
            !arrayObjs.JobIdList.includes(ts.Job_ID) &&
              arrayObjs.JobIdList.push(ts.job_ID)
            !arrayObjs.UserJobs.includes(ts.UserJobId) &&
              arrayObjs.UserJobs.push(ts.userJobId)
          }
        })
      }

      return arrayObjs
    },
    hoursTotal() {
      const nonConsiderableDays = this.agencyData.isWeekendConsidered ? 0 : 2
      const tooltipHoursTotalPerDay = this.hoursTotalPerDay.slice(
        0,
        this.hoursTotalPerDay.length - nonConsiderableDays
      )
      let hoursTotal = this.hoursTotalPerDay
        .map((item) => item.hours)
        .reduce((a, b) => a + b, 0)
      let percentTotal =
        this.hoursTotalPerDay
          .map((item) => item.percent)
          .reduce((a, b) => a + b, 0) / 5
      let tooltipHours = tooltipHoursTotalPerDay
        .map((item) => item.hours)
        .reduce((a, b) => a + b, 0)

      return {
        hours: percisionRound(hoursTotal, 0.01),
        percent: percisionRound(percentTotal, 0.01),
        tooltipHours: percisionRound(tooltipHours, 0.01),
      }
    },

    timesheetCanBeSubmitted() {
      const daysToSubmitValues = []
      const daysHoursRequired = []
      const internEmployee = []
      const hourlyEmployee = []
      const partTimeHours = []
      const fullTimeHours = []
      const contractorTimeHours = []
      let totalPartTimeHours = 0
      let totalcontractorTimeHours = 0
      let totalfullTimeHours = 0
      let totalhourlyEmployeeHour = 0
      let totalInternEmployeeHour = 0

      let dailyStatusArray = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }

      this.calendar.forEach((day, i) => {
        const dayReported = day.date.split('T')[0]
        const index = moment(dayReported).isoWeekday() - 1
        const timesheets = getTimesheets(this.timesheets, false, day.date)

        timesheets.forEach((item) => {
          let durationValue = this.agencyData.isPercentageAgency
            ? item.durationPercentageValue
            : item.DurationValue
          if (durationValue !== 0) {
            dailyStatusArray[index].push(item.timesheetStatus)
          }
        })
        if (
          day.isRequiredByUserContract &&
          (day.isWeekend === false || this.agencyData.isWeekendConsidered) &&
          !this.special(dayReported)
        ) {
          if (this.agencyData.isPercentageAgency) {
            daysToSubmitValues.push(this.hoursTotalPerDay[i].percent)
          } else {
            daysHoursRequired.push(
              this.timesheetHoursPerDayComputed.hoursPerDay[i]
            )
            daysToSubmitValues.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.employeeType == 'H') {
            hourlyEmployee.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.isPartTimeEmployee) {
            partTimeHours.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.externalEmployeeIndicator) {
            contractorTimeHours.push(this.hoursTotalPerDay[i].hours)
          }
          if (day.careerStageLevel == 'Intern') {
            internEmployee.push(this.hoursTotalPerDay[i].hours)
          }
          if (
            !day.externalEmployeeIndicator &&
            !day.isPartTimeEmployee &&
            day.careerStageLevel !== 'Intern' &&
            !this.agencyData.isPercentageAgency
          ) {
            fullTimeHours.push(this.hoursTotalPerDay[i].hours)
          } else if (
            !day.externalEmployeeIndicator &&
            !day.isPartTimeEmployee &&
            day.careerStageLevel !== 'Intern' &&
            this.agencyData.isPercentageAgency
          ) {
            fullTimeHours.push(this.hoursTotalPerDay[i].percent)
          }
        }
      })

      const isWholeWeekHourlyEmployee = this.calendar.every(
        (day) => day.employeeType == 'H'
      )
      const isWholeWeekInternEmployee = this.calendar.every(
        (day) => day.careerStageLevel == 'Intern'
      )
      const isWholeWeekPartTimeEmployee = this.calendar.every(
        (day) => day.isPartTimeEmployee
      )
      const isWholeWeekContractorTimeEmployee = this.calendar.every(
        (day) => day.externalEmployeeIndicator
      )
      const isWholeWeekFullTimeEmployee = this.calendar.every(
        (day) =>
          !day.isPartTimeEmployee &&
          !day.externalEmployeeIndicator &&
          day.careerStageLevel !== 'Intern' &&
          day.employeeType !== 'H'
      )

      totalInternEmployeeHour = internEmployee.reduce((a, b) => a + b, 0)
      totalhourlyEmployeeHour = hourlyEmployee.reduce((a, b) => a + b, 0)
      totalPartTimeHours = partTimeHours.reduce((a, b) => a + b, 0)
      totalcontractorTimeHours = contractorTimeHours.reduce((a, b) => a + b, 0)
      totalfullTimeHours = fullTimeHours.reduce((a, b) => a + b, 0)
      const fullTimeHoursExcludeWeekend =
        fullTimeHours.length > 5
          ? fullTimeHours.slice(0, 5)
          : fullTimeHours.slice(0, fullTimeHours.length - 1)
      const daysToSubmitValuesExcludeWeekend = daysToSubmitValues.slice(0, 5)

      if (this.agencyData.isPercentageAgency) {
        if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
          if (
            totalInternEmployeeHour > 0 ||
            totalPartTimeHours > 0 ||
            totalcontractorTimeHours > 0 ||
            totalfullTimeHours > 0 ||
            totalhourlyEmployeeHour > 0
          ) {
            return true
          }
        } else if (
          this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
        ) {
          if (
            isWholeWeekInternEmployee ||
            isWholeWeekPartTimeEmployee ||
            isWholeWeekContractorTimeEmployee ||
            isWholeWeekFullTimeEmployee ||
            isWholeWeekHourlyEmployee
          ) {
            if (isWholeWeekFullTimeEmployee) {
              return fullTimeHoursExcludeWeekend.every(
                (percent) => percent === 100
              )
            } else {
              return (
                contractorTimeHours.some((percent) => percent > 0) ||
                partTimeHours.some((percent) => percent > 0) ||
                hourlyEmployee.some((percent) => percent > 0) ||
                internEmployee.some((percent) => percent > 0)
              )
            }
          } else {
            return (
              fullTimeHoursExcludeWeekend.every((percent) => percent === 100) &&
              (contractorTimeHours.some((percent) => percent > 0) ||
                partTimeHours.some((percent) => percent > 0) ||
                hourlyEmployee.some((percent) => percent > 0) ||
                internEmployee.some((percent) => percent > 0))
            )
          }
        } else {
          return daysToSubmitValuesExcludeWeekend.every(
            (percent) => percent === 100
          )
        }
      } else {
        if (this.agencyData.dwIndicator === '1') {
          if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
            if (
              totalInternEmployeeHour > 0 ||
              totalPartTimeHours > 0 ||
              totalcontractorTimeHours > 0 ||
              totalfullTimeHours > 0 ||
              totalhourlyEmployeeHour > 0
            ) {
              return daysToSubmitValues.some(
                (hours, i) =>
                  hours > 0 &&
                  dailyStatusArray[i].find((i) => i === 'Unsubmitted')
              )
            }
          } else if (
            this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
          ) {
            if (
              isWholeWeekInternEmployee ||
              isWholeWeekPartTimeEmployee ||
              isWholeWeekContractorTimeEmployee ||
              isWholeWeekFullTimeEmployee ||
              isWholeWeekHourlyEmployee
            ) {
              if (isWholeWeekFullTimeEmployee) {
                return fullTimeHours.some(
                  (hours, i) =>
                    hours >= daysHoursRequired[i] &&
                    dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                )
              } else {
                return (
                  partTimeHours.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  ) ||
                  contractorTimeHours.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  ) ||
                  hourlyEmployee.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  ) ||
                  internEmployee.some(
                    (hours, i) =>
                      hours > 0 &&
                      dailyStatusArray[i].find((i) => i === 'Unsubmitted')
                  )
                )
              }
            } else {
              return daysToSubmitValues.some(
                (hours, i) =>
                  hours > 0 &&
                  dailyStatusArray[i].find((i) => i === 'Unsubmitted')
              )
            }
          } else {
            return daysToSubmitValues.some(
              (hours, i) =>
                hours >= daysHoursRequired[i] &&
                dailyStatusArray[i].find((i) => i === 'Unsubmitted')
            )
          }
        } else if (this.agencyData.dwIndicator === '2') {
          if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
            if (
              totalInternEmployeeHour > 0 ||
              totalPartTimeHours > 0 ||
              totalcontractorTimeHours > 0 ||
              totalfullTimeHours > 0 ||
              totalhourlyEmployeeHour > 0
            ) {
              return true
            }
          } else if (
            this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
          ) {
            if (
              isWholeWeekInternEmployee ||
              isWholeWeekPartTimeEmployee ||
              isWholeWeekContractorTimeEmployee ||
              isWholeWeekFullTimeEmployee ||
              isWholeWeekHourlyEmployee
            ) {
              if (isWholeWeekFullTimeEmployee) {
                return fullTimeHoursExcludeWeekend.every(
                  (hours, i) => hours >= daysHoursRequired[i]
                )
              } else {
                return (
                  partTimeHours.some((hours) => hours > 0) ||
                  contractorTimeHours.some((hours) => hours > 0) ||
                  hourlyEmployee.some((hours) => hours > 0) ||
                  internEmployee.some((hours) => hours > 0)
                )
              }
            } else {
              return (
                fullTimeHoursExcludeWeekend.every(
                  (hours, i) => hours >= daysHoursRequired[i]
                ) &&
                (partTimeHours.some((hours) => hours > 0) ||
                  contractorTimeHours.some((hours) => hours > 0) ||
                  hourlyEmployee.some((hours) => hours > 0) ||
                  internEmployee.some((hours) => hours > 0))
              )
            }
          } else {
            return daysToSubmitValuesExcludeWeekend.every(
              (hours, i) => hours >= daysHoursRequired[i]
            )
          }
        } else if (this.agencyData.dwIndicator === '3') {
          let totalSubmittedTime = daysToSubmitValues.reduce((a, b) => a + b, 0)
          if (this.agencyData.allowAllEmployeesSubmissionWithoutRestriction) {
            if (
              totalInternEmployeeHour > 0 ||
              totalPartTimeHours > 0 ||
              totalcontractorTimeHours > 0 ||
              totalfullTimeHours > 0 ||
              totalhourlyEmployeeHour > 0
            ) {
              return true
            }
          } else if (
            this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction
          ) {
            if (
              isWholeWeekInternEmployee ||
              isWholeWeekPartTimeEmployee ||
              isWholeWeekContractorTimeEmployee ||
              isWholeWeekFullTimeEmployee ||
              isWholeWeekHourlyEmployee
            ) {
              if (isWholeWeekFullTimeEmployee) {
                return (
                  totalSubmittedTime >=
                  this.timesheetHoursPerDayComputed.totalHours
                )
              } else {
                return (
                  totalPartTimeHours > 0 ||
                  totalcontractorTimeHours > 0 ||
                  totalhourlyEmployeeHour > 0 ||
                  totalInternEmployeeHour > 0
                )
              }
            } else {
              return (
                totalSubmittedTime >=
                this.timesheetHoursPerDayComputed.totalHoursFTPT
              )
            }
          } else {
            return (
              totalSubmittedTime >= this.timesheetHoursPerDayComputed.totalHours
            )
          }
        }
      }
      return false
    },
    dailyStatus() {
      const dailyStatusArrayByJob = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }

      this.calendar.forEach((day) => {
        const i = moment(day.date.split('T')[0]).isoWeekday() - 1
        const timesheets = getTimesheets(this.timesheets, false, day.date)

        timesheets.forEach((item) => {
          let durationValue = this.agencyData.isPercentageAgency
            ? item.durationPercentageValue
            : item.durationValue
          if (durationValue !== 0) {
            let dailyStats = {
              status: item.timesheetStatus,
              value: durationValue,
            }
            dailyStatusArrayByJob[i].push(dailyStats)
          }
        })
      })

      let dayStatus = []
      const status = {
        submitted: 'submitted',
        unsubmitted: 'unsubmitted',
        other: 'other',
        none: 'none',
      }

      const state = {
        disabled: 'disabled',
        enabled: 'enabled',
      }

      for (const key in dailyStatusArrayByJob) {
        let timeNOTSafe =
          this.hoursTotalPerDay[key].hours <
          this.timesheetHoursPerDayComputed.hoursPerDay[key]

        if (this.agencyData.isPercentageAgency) {
          timeNOTSafe = this.hoursTotalPerDay[key].percent < 100
        }

        const isWeekendEntryEnabled =
          key > 4 && this.firstAvailableContract.weekendEntryEnabled

        timeNOTSafe = isWeekendEntryEnabled ? false : timeNOTSafe

        const doesTimesheetExist = dailyStatusArrayByJob[key].length

        if (!doesTimesheetExist) {
          dayStatus.push({ status: status.none, state: state.disabled })
          continue
        }

        const areAllTimesheetsSubmitted = dailyStatusArrayByJob[key].every(
          (item) => item.status === 'Submitted'
        )

        if (areAllTimesheetsSubmitted) {
          dayStatus.push({ status: status.submitted, state: state.disabled })
          continue
        }

        const isAnyTimesheetUnsubmitted = dailyStatusArrayByJob[key].some(
          (item) => item.status === 'Unsubmitted'
        )

        if (!isAnyTimesheetUnsubmitted) {
          dayStatus.push({ status: status.other, state: state.disabled })
          continue
        }

        if (isAnyTimesheetUnsubmitted && timeNOTSafe) {
          dayStatus.push({ status: status.unsubmitted, state: state.disabled })
          continue
        }

        if (isAnyTimesheetUnsubmitted && !timeNOTSafe) {
          dayStatus.push({ status: status.unsubmitted, state: state.enabled })
          continue
        }
      }

      return dayStatus
    },
    showTimesheetCopyBtn() {
      if (this.timesheetJobs.length > 0) {
        return this.timesheetJobs.every((item) => item.clarizenIsActiveUserJob)
      }

      return false
    },
  },
  methods: {
    ...mapActions('storeTimesheets', ['loadData', 'addNewTimesheet']),
    ...mapMutations('storeTimesheets', [
      'setLoading',
      'setTimesheetDeleteError',
      'setSelfConsentSubmitDialog',
      'setTimesheetSubmitConfirmation',
      'setPerformRemoveButtonFocus',
      'setDeDupTimesheetJobs',
      'setTimesheetHideJobError',
      'setTimesheetEditJobError',
      'setSelfConsentData',
    ]),
    getAriaLabel(i, day) {
      if (
        !day ||
        !day.dayNameLong ||
        typeof this.dailyStatus[i] === 'undefined'
      ) {
        return 'Submit'
      }

      const defaultText = `${this.getHoursOrPercentageForDay(i)} ${
        this.isTodayPercentage(i) ? 'percent' : 'hours'
      } for ${day.dayNameLong} ${day.day}th`

      switch (this.dailyStatus[i]) {
        case 'submitted':
          return `${this.$t('timesheetUI.Btns.BtnSubmitted')} ${defaultText}`
        case 'rejected':
          return `${this.$t('timesheetUI.Btns.BtnRejected')} ${defaultText}`
        case 'partiallyapproved':
          return `${this.$t(
            'timesheetUI.Btns.BtnPartiallyApprovedSmaller'
          )} ${defaultText}`
        case 'approved':
          return `${this.$t('timesheetUI.Btns.BtnApproved')} ${defaultText}`
        default:
          return `Submit ${defaultText}`
      }
    },
    getHoursOrPercentageForDay(i) {
      return this.IsPercentageByDay[i].some((entry) => entry === true)
        ? this.hoursTotalPerDay[i].percent
        : this.hoursTotalPerDay[i].hours
    },
    isTodayPercentage(i) {
      return this.IsPercentageByDay[i].some((entry) => entry === true)
    },
    special(day) {
      if (
        this.userData.altairCode === '10038440' &&
        (moment(day).isSame('2021-05-10') || moment(day).isSame('2021-05-11'))
      ) {
        return true
      }

      return false
    },

    timesheetSubmitWeek() {
      const perDayTotalDurationValidation = validateTotalDurationPerDay(
        this.hoursTotalPerDay,
        this.agencyData
      )
      if (!perDayTotalDurationValidation.isValid) {
        const dialogAlert = {
          dialogAlertType: 'error',
          // dialogAlertTitle: i18n.t('', window.CULTURE),
          dialogAlertMessage: perDayTotalDurationValidation.message,
        }

        EventBus.$emit('dialogAlertShow', dialogAlert)
        return
      }
      if (
        this.isLocationActiveForThisWeek &&
        !this.showSelfConsentSubmitDialog &&
        this.userData.showSelfAttestationPopupOnTimesheetSubmit
      ) {
        const params = {
          timesheetIds: [
            ...Object.values(this.timesheetIdArrays.TimesheetIdList),
          ].flat(),
          altairCode: this.userData.altairCode,
        }
        clientMicroServicePost(
          'timesheet/Timesheets/showtimesheetsummarybylocationdays',
          params
        ).then((r) => {
          this.setSelfConsentData(r.data.value)
        })
        this.submitTimesheetDayOrWeek = this.timesheetSubmitWeek
        this.selfConsentDialogTitle = `${this.selectedDate.startOfWeekLong} - ${this.selectedDate.endOfWeekLong}`
        this.setSelfConsentSubmitDialog(true)
        return
      }
      this.setLoading(true)
      this.updateSubmittingTimesheets(true)

      const params = {
        timesheetIds: [
          ...Object.values(this.timesheetIdArrays.TimesheetIdList),
        ].flat(),
        altairCode: this.userData.altairCode,
        managerAltairCode:
          this.userData.altairCode !== this.altairCode
            ? this.altairCode
            : undefined,
      }

      clientMicroServicePost('timesheet/Timesheets/submit', params).then(() => {
        this.setSelfConsentSubmitDialog(false)
        this.setTimesheetSubmitConfirmation({
          dialog: true,
          data: {
            totalHours: this.agencyData.isPercentageAgency
              ? this.hoursTotal.percent
              : this.hoursTotal.hours,
            submittedDate: formatDateMonthAsWord(moment().format('YYYY-MM-DD')),
            isPercentage: this.agencyData.isPercentageAgency,
          },
        })

        this.loadData('timesheets').then(() => {
          this.updateSubmittingTimesheets(false)
          this.setLoading(false)
        })
      })
    },
    timesheetSubmitDay(dayIndex, day) {
      const perDayTotalDurationValidation = validateTotalDurationPerDay(
        this.hoursTotalPerDay,
        this.agencyData,
        dayIndex
      )
      if (!perDayTotalDurationValidation.isValid) {
        const dialogAlert = {
          dialogAlertType: 'error',
          // dialogAlertTitle: i18n.t('', window.CULTURE),
          dialogAlertMessage: perDayTotalDurationValidation.message,
        }

        EventBus.$emit('dialogAlertShow', dialogAlert)
        return
      }
      if (
        this.isLocationActiveForThisDay(day.date) &&
        !this.showSelfConsentSubmitDialog &&
        this.userData.showSelfAttestationPopupOnTimesheetSubmit
      ) {
        const params = {
          timesheetIds: this.timesheetIdArrays.TimesheetIdList[dayIndex],
          altairCode: this.userData.altairCode,
        }
        clientMicroServicePost(
          'timesheet/Timesheets/showtimesheetsummarybylocationdays',
          params
        ).then((r) => {
          this.setSelfConsentData(r.data.value)
        })
        this.submitTimesheetDayOrWeek = () => {
          this.timesheetSubmitDay(dayIndex, day)
        }
        const locale = window.CULTURE === 'fr-FR' ? 'fr' : 'en'
        this.selfConsentDialogTitle = moment(new Date(day.date.split('T')[0]))
          .locale(locale)
          .format('dddd, DD MMM')
        this.setSelfConsentSubmitDialog(true)
        return
      }
      this.setLoading(true)
      const params = {
        timesheetIds: this.timesheetIdArrays.TimesheetIdList[dayIndex],
        altairCode: this.userData.altairCode,
        managerAltairCode:
          this.userData.altairCode !== this.altairCode
            ? this.altairCode
            : undefined,
      }

      clientMicroServicePost('timesheet/Timesheets/submit', params).then(
        (r) => {
          if (r.status === 200) {
            this.setSelfConsentSubmitDialog(false)
            this.setTimesheetSubmitConfirmation({
              dialog: true,
              data: {
                totalHours: this.agencyData.isPercentageAgency
                  ? this.hoursTotalPerDay[dayIndex].percent
                  : this.hoursTotalPerDay[dayIndex].hours,
                submittedDate: formatDateMonthAsWord(
                  moment().format('YYYY-MM-DD')
                ),
                isPercentage: this.agencyData.isPercentageAgency,
              },
            })

            this.loadData('timesheets').then(() => {
              this.updateSubmittingTimesheets(false)
              this.setLoading(false)
            })
          } else {
            const dialogAlert = {
              dialogAlertType: 'error',
              dialogAlertTitle: i18n.t(
                'timesheetUI.ValidationMessages.GenericErrorTitle',
                window.CULTURE
              ),
              dialogAlertMessage: i18n.t(
                'timesheetUI.ValidationMessages.AlreadySubmittedTimesheetsMessage',
                window.CULTURE
              ),
            }

            EventBus.$emit('dialogAlertShow', dialogAlert)

            this.setLoading(false)
          }
        }
      )
    },
    copyPrevious() {
      const params = {}

      clientMicroServicePost(
        `timesheet/Jobs/copy-user-jobs-from-previous-week?altairCode=${this.userData.altairCode}&Week=${this.selectedDate.weekOfYear}&Year=${this.selectedDate.year}&ExcludePPM=false&Ver=2`,
        params
      ).then(() => {
        this.loadData('jobs-timesheets')
      })
    },
  },
}
</script>

<style scoped>
.footer-action {
  box-shadow: 0 -6px 8px -4px #e6e6e6;
}
</style>
