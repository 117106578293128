<template>
  <div style="position: relative">
    <DocCheckIcon
      v-if="isProjectAlreadyAddedToTimesheet()"
      style="position: absolute; z-index: 1; left: -14px; top: 6px"
    />
    <v-card outlined class="mb-3">
      <v-card-text class="px-5 py-3">
        <div class="d-flex align-center gap-6">
          <div class="flex-grow-1 font-size--10 job-details__meta">
            <div
              class="font-color--primary font-weight--600 mb-2 font-size--12"
            >
              <span>{{ job.jobName }}</span>
            </div>

            <div class="accent--text mb-1">
              <span class="mr-1 font-color--body">•</span>
              <span v-if="isJobAssignedProject">
                {{ $t('timesheetUI.TimesheetJobs.ClarizenAssignProjectLabel') }}
              </span>
              <span v-else>{{
                $t('timesheetUI.TimesheetJobs.ClarizenAssignTaskLabel')
              }}</span>
              <v-icon
                class="cross-company-rule__label"
                color="accent"
                :size="10"
                >mdi-check</v-icon
              >
            </div>
            <div class="d-flex flex-wrap secondary--text">
              <span class="mr-1">{{ job.clientName }}</span> •
              <span class="mx-1">{{ job.agencyName }}</span> •
              <span class="ml-1">{{ job.job_ID }}</span>
            </div>
            <div v-if="job.crossCompanyRuleTypeName" class="accent--text my-1">
              <span class="mr-1">•</span>
              <span class="font-size--10">{{
                job.crossCompanyRuleTypeName
              }}</span>
              <v-icon
                class="cross-company-rule__label"
                color="accent"
                :size="10"
                >mdi-star</v-icon
              >
            </div>
            <span
              v-if="job.clarizenTaskName || job.isClarizenAssignedProject"
              class="font-size--10 font-weight--600 accent-light primary--text rounded-lg text-uppercase px-2 mt-2 d-inline-block"
              style="padding-top: 2px; padding-bottom: 2px"
            >
              <template
                v-if="job.isClarizenTask && job.clarizenTaskExternalId"
                >{{ job.clarizenTaskName }}</template
              >

              <template
                v-if="
                  job.isClarizenAssignedProject && !job.clarizenTaskExternalId
                "
                >{{ job.clarizenProjectName }}</template
              >
            </span>
            <div
              v-if="job.isClarizenAssignedProject"
              class="d-flex flex-wrap mt-2 justify-space-between align-center"
            >
              <v-label
                for="PPMTaskDropdown"
                class="primary--text font-size--12 font-weight-regular w-60px"
                >PPM task</v-label
              >
              <VSelect
                :value="selectedTask.PPMTask || ''"
                :items="
                  (PPMTasksByJobId[job.job_ID] &&
                    PPMTasksByJobId[job.job_ID].data) ||
                  []
                "
                :loading="
                  PPMTasksByJobId[job.job_ID] &&
                  PPMTasksByJobId[job.job_ID].isDataLoading
                "
                :placeholder="
                  $t(
                    'timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder'
                  )
                "
                item-text="label"
                item-value="value"
                outlined
                ref="joblist_ppm_select"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                  nudgeBottom: 0,
                }"
                id="PPMTaskDropdown"
                dense
                hide-details
                append-icon="mdi-chevron-down"
                class="font-size--14"
                @keydown.space.prevent="
                  loadPPMTasks(job, PPMTasksByJobId[job.job_ID])
                "
                @input="setSelectedTask(job.job_ID, 'PPMTask', $event)"
                @click="loadPPMTasks(job, PPMTasksByJobId[job.job_ID])"
                :error="!validations.PPMTask.valid"
                :aria-describedby="
                  validations.PPMTask.msg ? 'ppmTaskError' : null
                "
                @update:list-index="
                  (index) => setHighlightedTaskForSR(index, 'PPMTask')
                "
                @keydown.up="announceChange('PPMTask')"
                @keydown.down="announceChange('PPMTask')"
              >
                <template v-slot:no-data>
                  <div
                    v-if="
                      !PPMTasksByJobId[job.job_ID] ||
                      (PPMTasksByJobId[job.job_ID] &&
                        PPMTasksByJobId[job.job_ID].isDataLoading)
                    "
                  >
                    <VListItem
                      ><VListItem>
                        <VListItemContent>
                          <VListItemTitle>Loading...</VListItemTitle>
                        </VListItemContent>
                      </VListItem></VListItem
                    >
                  </div>
                  <div v-else>
                    <VListItem>
                      <VListItemContent>
                        <VListItemTitle>{{
                          $t('General.Nodataavailable')
                        }}</VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                  </div>
                </template>
              </VSelect>
            </div>

            <div
              aria-live="polite"
              aria-atomic="true"
              class="sr-only"
              ref="announceRegionPPMTask"
            ></div>

            <div
              :key="highlightedTask.PPMTask"
              aria-live="polite"
              aria-atomic="true"
              class="sr-only"
            >
              {{ highlightedTask.PPMTask }}
            </div>

            <div
              v-if="validations.PPMTask.msg"
              id="ppmTaskError"
              class="job-validation-message error--text mt-2"
              role="alert"
              aria-live="assertive"
            >
              {{ validations.PPMTask.msg }}
            </div>

            <div
              v-if="isAltairTaskRequired"
              class="d-flex flex-wrap mt-2 justify-space-between align-center"
            >
              <VLabel
                for="altairTaskDropdown"
                class="primary--text font-size--12 font-weight-regular w-60px"
              >
                {{ $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelTask') }}
              </VLabel>
              <VSelect
                :value="selectedTask.altairTask || ''"
                :items="
                  (altairTasksByJobId[job.job_ID] &&
                    altairTasksByJobId[job.job_ID].data) ||
                  []
                "
                :loading="
                  altairTasksByJobId[job.job_ID] &&
                  altairTasksByJobId[job.job_ID].isDataLoading
                "
                :placeholder="
                  $t(
                    'timesheetUI.EntryFormLabels.TimesheetEntryPsTaskPlaceholder'
                  )
                "
                item-text="label"
                item-value="value"
                outlined
                dense
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                  nudgeBottom: 0,
                }"
                id="altairTaskDropdown"
                ref="joblist_altair_select"
                append-icon="mdi-chevron-down"
                class="font-size--14"
                @keydown.space.prevent="
                  loadAltairTasks(job, altairTasksByJobId[job.job_ID])
                "
                @input="setSelectedTask(job.job_ID, 'altairTask', $event)"
                @click="loadAltairTasks(job, altairTasksByJobId[job.job_ID])"
                hide-details
                :aria-describedby="
                  validations.altairTask.msg ? 'altairTaskError' : null
                "
                @update:list-index="
                  (index) => setHighlightedTaskForSR(index, 'altairTask')
                "
                @keydown.up="announceChange('altairTask')"
                @keydown.down="announceChange('altairTask')"
              >
                <template v-slot:no-data>
                  <div
                    v-if="
                      !altairTasksByJobId[job.job_ID] ||
                      (altairTasksByJobId[job.job_ID] &&
                        altairTasksByJobId[job.job_ID].isDataLoading)
                    "
                  >
                    <VListItem>
                      <VListItemContent>
                        <VListItemTitle>Loading...</VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                  </div>
                  <div v-else>
                    <VListItem>
                      <VListItemContent>
                        <VListItemTitle>{{
                          $t('General.Nodataavailable')
                        }}</VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                  </div>
                </template>
              </VSelect>
            </div>

            <div
              aria-live="polite"
              aria-atomic="true"
              class="sr-only"
              ref="announceRegionAltairTask"
            ></div>

            <div
              :key="highlightedTask.altairTask"
              aria-live="polite"
              aria-atomic="true"
              class="sr-only"
            >
              {{ highlightedTask.altairTask }}
            </div>

            <div
              v-if="validations.altairTask.msg"
              id="altairTaskError"
              class="job-validation-message error--text mt-2"
              role="alert"
              aria-live="assertive"
            >
              {{ validations.altairTask.msg }}
            </div>
          </div>

          <v-btn
            depressed
            text
            small
            @click="addAssignedJobToTimesheet(job)"
            class="pl-1 pr-2 font-weight--600"
            :disabled="loading"
          >
            <span class="d-flex align-center">
              <template v-if="!isJobAssignedProject">
                <v-icon color="primary">mdi-plus</v-icon>
                <span
                  :aria-label="`${job.job_Name || job.jobName}, ${
                    !validations.altairTask.valid
                      ? $t('timesheetUI.SearchJobs.CantBeAddToTimesheet')
                      : $t('timesheetUI.SearchJobs.BtnAddToTimesheet')
                  } job`"
                >
                  {{
                    !validations.altairTask.valid
                      ? $t('timesheetUI.SearchJobs.CantBeAddToTimesheet')
                      : $t('timesheetUI.SearchJobs.BtnAddToTimesheet')
                  }}
                </span>
              </template>

              <template v-if="isJobAssignedProject">
                <span
                  :aria-label="`${job.job_Name || job.jobName}, ${$t(
                    'timesheetUI.SearchJobs.BtnAddToTimesheet'
                  )} job`"
                >
                  <template
                    v-if="
                      isProjectAlreadyAddedToTimesheet() &&
                      selectedTask.PPMTask &&
                      ((!isAltairTaskRequired &&
                        !isPPMTaskAlreadyAddedToTimesheet()) ||
                        (isAltairTaskRequired &&
                          !isAltairAndPPMTaskAlreadyAddedToTimesheet(
                            job.job_ID
                          )))
                    "
                  >
                    <v-icon color="accent">mdi-plus</v-icon>
                    <span
                      class="font-size accent--text"
                      :style="`opacity: ${loading ? 0.26 : 1}`"
                    >
                      {{ $t('General.Addnewrow') }}
                    </span>
                  </template>

                  <template v-else-if="isPPMTaskAlreadyAddedToTimesheet()">
                    <v-icon color="primary">mdi-close</v-icon>
                    <span class="font-size">{{
                      $t('timesheetUI.SearchJobs.CantBeAddToTimesheet')
                    }}</span>
                  </template>

                  <template v-else>
                    <v-icon color="primary">mdi-plus</v-icon>

                    {{ $t('timesheetUI.SearchJobs.BtnAddToTimesheet') }}
                  </template>
                </span>
              </template>
            </span>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DocCheckIcon from '@Components/Icons/DocCheckIcon.vue'
import i18n from '@Shared/i18n'

import {
  mapState,
  mapGetters,
  mapActions,
  // mapMutations
} from 'vuex'

export default {
  name: 'AssignedJobCard',

  props: ['job', 'closeSearchJobDialog', 'showSearchJobDialog'],

  components: {
    DocCheckIcon,
  },

  data() {
    return {
      selectedTask: {},
      validations: { PPMTask: { valid: true }, altairTask: { valid: true } },
      highlightedTask: {},
    }
  },

  computed: {
    ...mapState('storeTimesheets', [
      'PPMTasksByJobId',
      'altairTasksByJobId',
      'loading',
    ]),

    ...mapGetters('storeTimesheets', ['timesheetJobs']),

    isAltairTaskRequired() {
      return (
        this.job.availableFeaturesBillableIndicators.length &&
        this.job.availableFeaturesBillableIndicators?.[0].availablefeatures
          .features.task.required
      )
    },

    isJobAssignedProject() {
      if (
        this.job.isClarizenAssignedProject ||
        !this.job.clarizenTaskExternalId
      ) {
        return true
      }

      return false
    },

    isJobAssignedTaskWithAltairTask() {
      return (
        this.job.clarizenTaskExternalId &&
        this.job.availableFeaturesBillableIndicators?.[0].availablefeatures
          .features.task.required
      )
    },
  },

  methods: {
    ...mapActions('storeTimesheets', [
      'fetchPPMTasks',
      'fetchAltairTasks',
      'addAssignedTaskToTimesheet',
      'addAssignedProjectTaskToTimesheet',
    ]),

    setHighlightedTaskForSR(index, taskType) {
      const tasks =
        taskType === 'PPMTask' ? this.PPMTasksByJobId : this.altairTasksByJobId

      this.$set(
        this.highlightedTask,
        taskType,
        tasks[this.job.job_ID]?.data[index]?.label || ''
      )
    },

    announceChange(taskType) {
      if (!this.selectedTask[taskType]) return
      const regionRef =
        taskType === 'PPMTask'
          ? 'announceRegionPPMTask'
          : 'announceRegionAltairTask'
      const region = this.$refs[regionRef]
      region.textContent = this.selectedTask[taskType].label
    },

    loadPPMTasks(job, fetchedPPMTasks) {
      if (fetchedPPMTasks?.isDataLoaded) return

      this.fetchPPMTasks({ jobId: job.job_ID }).then(() => {
        this.$refs?.joblist_ppm_select.$children[1].activate()
      })
    },

    loadAltairTasks(job, fetchedAltairTasks) {
      if (fetchedAltairTasks?.isDataLoaded) return

      this.fetchAltairTasks({ jobId: job.job_ID }).then(() => {
        this.$refs?.joblist_altair_select.$children[1].activate()
      })
    },

    setSelectedTask(jobId, taskType, selectedTask) {
      const selectedTaskCopy = JSON.parse(JSON.stringify(this.selectedTask))

      if (taskType === 'PPMTask') {
        selectedTaskCopy.PPMTask = selectedTask
      }

      if (taskType === 'altairTask') {
        selectedTaskCopy.altairTask = selectedTask
      }

      this.selectedTask = selectedTaskCopy

      this.setValidation(selectedTask, taskType)
    },

    setValidation(selectedTask, taskType) {
      if (taskType === 'PPMTask')
        this.validations.PPMTask = this.validatePPMTask(selectedTask)

      if (taskType === 'altairTask')
        this.validations.altairTask = this.validateAltairTask()

      if (
        this.validations.PPMTask.msg ===
          i18n.t(
            'timesheetUI.SearchJobValidationMessage.PPMAndAltairTaskAlreadyExists',
            window.CULTURE
          ) ||
        this.validations.altairTask.msg ===
          i18n.t(
            'timesheetUI.SearchJobValidationMessage.PPMAndAltairTaskAlreadyExists',
            window.CULTURE
          )
      ) {
        this.validations.PPMTask = this.validatePPMTask(selectedTask)
        this.validations.altairTask = this.validateAltairTask()
      }
    },

    validatePPMTask(taskId) {
      const isProjectAddedToTimesheet = this.isProjectAlreadyAddedToTimesheet()
      if (!isProjectAddedToTimesheet) return { valid: true }

      if (isProjectAddedToTimesheet) {
        if (this.isAltairTaskRequired) {
          const isPPMAndAltairTaskCombinationAddedToTimesheet =
            this.isAltairAndPPMTaskAlreadyAddedToTimesheet(this.job.job_ID)

          if (isPPMAndAltairTaskCombinationAddedToTimesheet) {
            return {
              valid: false,
              msg: i18n.t(
                'timesheetUI.SearchJobValidationMessage.PPMAndAltairTaskAlreadyExists',
                window.CULTURE
              ),
            }
          }

          return { valid: true }
        }

        const isPPMTaskAddedToTimesheet =
          this.isPPMTaskAlreadyAddedToTimesheet(taskId)

        if (isPPMTaskAddedToTimesheet) {
          return {
            valid: false,
            msg: i18n.t(
              'timesheetUI.SearchJobValidationMessage.taskAlreadyExists',
              window.CULTURE
            ),
          }
        }

        return { valid: true, msg: '' }
      }
    },

    validateAltairTask() {
      const isProjectAddedToTimesheet = this.isProjectAlreadyAddedToTimesheet()

      if (this.isJobAssignedProject && !isProjectAddedToTimesheet)
        return { valid: true }

      if (isProjectAddedToTimesheet) {
        const isPPMAndAltairTaskCombinationAddedToTimesheet =
          this.isAltairAndPPMTaskAlreadyAddedToTimesheet(this.job.job_ID)

        if (isPPMAndAltairTaskCombinationAddedToTimesheet) {
          return {
            valid: false,
            msg: i18n.t(
              'timesheetUI.SearchJobValidationMessage.PPMAndAltairTaskAlreadyExists',
              window.CULTURE
            ),
          }
        }

        return { valid: true }
      }

      if (!this.isJobAssignedProject) {
        if (
          this.job?.availableFeaturesBillableIndicators?.some(
            (features) => features?.availablefeatures?.features?.task?.required
          ) &&
          this.isAltairTaskAlreadyAddedToTimesheet(
            this.job.job_ID,
            this.selectedTask.altairTask
          )
        ) {
          return {
            valid: false,
            msg: i18n.t(
              'timesheetUI.SearchJobValidationMessage.AltairTaskAlreadyExists',
              window.CULTURE
            ),
          }
        }
        return { valid: true }
      }
    },

    isAltairAndPPMTaskAlreadyAddedToTimesheet(job_ID) {
      const { PPMTask: PPMTaskId, altairTask: altairTaskId } = this.selectedTask
      return this.timesheetJobs.some((job) => {
        if (
          job.job_ID === job_ID &&
          job.clarizenTaskExternalId === PPMTaskId &&
          altairTaskId &&
          job.task === altairTaskId
        ) {
          return true
        }
        return false
      })
    },
    isAltairTaskAlreadyAddedToTimesheet(job_ID) {
      const { altairTask: altairTaskId } = this.selectedTask
      const value = this.timesheetJobs.some((job) => {
        if (
          job.job_ID === job_ID &&
          job.clarizenTaskExternalId === this.job.clarizenTaskExternalId &&
          altairTaskId &&
          job.task === altairTaskId
        ) {
          return true
        }
        return false
      })
      return value
    },

    isPPMTaskAlreadyAddedToTimesheet(taskId) {
      const taskIdToLookup = taskId || this.selectedTask.PPMTask

      return this.timesheetJobs.some((job) => {
        if (
          job.job_ID === this.job.job_ID &&
          job.clarizenTaskExternalId === taskIdToLookup &&
          job.isJobVisible_V2 &&
          !job.selfAssignmentWithManageTasking
        ) {
          return true
        }
        return false
      })
    },

    // isAltairTaskAlreadyAddedToTimesheet(jobId, taskId) {
    //   this.timesheetJobs.filter((job) => {
    //     if (job.job_ID === jobId && job.altairTask === taskId) {
    //       return true
    //     }
    //     return false
    //   })
    // },

    isAnyAltairTaskFromAssignedProjectAddedToTimesheet(jobId) {
      this.timesheetJobs.some((job) => job.job_ID === jobId)
    },

    isProjectAlreadyAddedToTimesheet() {
      return this.timesheetJobs.some((timesheetJob) => {
        return (
          this.job.job_ID === timesheetJob.job_ID &&
          timesheetJob.isClarizenAssignedProject &&
          !timesheetJob.selfAssignmentWithManageTasking
        )
      })
    },

    resetJobCard() {
      this.selectedTask = {}
      this.validations = {
        PPMTask: { valid: true },
        altairTask: { valid: true },
      }
    },

    async addAssignedJobToTimesheet(job) {
      if (job.isClarizenAssignedProject && !this.selectedTask.PPMTask) {
        this.validations.PPMTask = {
          valid: false,
          msg: 'To add a job to your timesheet, you must first select the task name.',
        }

        this.$nextTick(() => {
          if (this.$refs.joblist_ppm_select) {
            const selectComponent = this.$refs.joblist_ppm_select
            const inputElement = selectComponent.$el.querySelector('input')
            if (inputElement) {
              inputElement.focus()
            }
          }
        })
      }

      if (this.isAltairTaskRequired && !this.selectedTask.altairTask) {
        this.validations.altairTask = {
          valid: false,
          msg: 'To add a job to your timesheet, you must first select the task name.',
        }

        this.$nextTick(() => {
          if (this.$refs.joblist_altair_select) {
            const selectComponent = this.$refs.joblist_altair_select
            const inputElement = selectComponent.$el.querySelector('input')
            if (inputElement) {
              inputElement.focus()
            }
          }
        })
      }

      if (
        !this.validations.PPMTask.valid ||
        !this.validations.altairTask.valid
      ) {
        return
      }

      if (
        (!this.isAltairTaskRequired &&
          this.isPPMTaskAlreadyAddedToTimesheet()) ||
        (this.isAltairTaskRequired &&
          this.isAltairAndPPMTaskAlreadyAddedToTimesheet(job.job_ID))
      ) {
        return
      }
      // if (this.isPPMTaskAlreadyAddedToTimesheet()) {
      //   return
      // }

      const params = { job }

      if (this.selectedTask.PPMTask) {
        params.PPMTaskId = this.selectedTask.PPMTask
        const PPMTask = this.PPMTasksByJobId[this.job.job_ID].data.find(
          (task) => task.value === this.selectedTask.PPMTask
        )
        params.PPMTaskName = PPMTask.label
      }

      if (this.selectedTask.altairTask) {
        params.altairTaskId = this.selectedTask.altairTask
        const altairTask = this.altairTasksByJobId[this.job.job_ID].data.find(
          (task) => task.value === this.selectedTask.altairTask
        )
        params.altairTaskName = altairTask.label
      }

      if (this.isJobAssignedProject || this.isJobAssignedTaskWithAltairTask) {
        const response = await this.addAssignedProjectTaskToTimesheet(params)
        setTimeout(() => {
          document
            .querySelector(
              `[data-timsheet-cell-job="${response.data.value.id}"]`
            )
            ?.focus()
        }, 1000)
      } else {
        await this.addAssignedTaskToTimesheet(params)
      }

      this.closeSearchJobDialog()
      this.resetJobCard()
    },
  },

  watch: {
    showSearchJobDialog: {
      immediate: true,
      handler(newVal) {
        if (!newVal) this.resetJobCard()
        if (newVal) {
          this.$nextTick(() => {
            document
              .querySelector('.timesheet-search-job--items')
              ?.addEventListener('scroll', () => {
                this.$refs?.joblist_ppm_select?.$children[1].activate()
                this.$refs?.joblist_altair_select?.$children[1].activate()
              })
          })
        } else {
          document
            .querySelector('.timesheet-search-job--items')
            ?.removeEventListener('scroll', () => {
              this.$refs?.joblist_ppm_select?.$children[1].activate()
              this.$refs?.joblist_altair_select?.$children[1].activate()
            })
        }
      },
    },
  },
}
</script>
-
<style lang="scss" scoped>
.w-60px {
  width: 9ch;
}
</style>
