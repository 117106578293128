var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "offset-y": "",
        attach: ".timesheet-header__search",
        "content-class": "filter-panel elevation-8",
        "nudge-bottom": "10",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2",
                      attrs: {
                        icon: "",
                        small: "",
                        color: "primary",
                        "aria-label": _vm.$t(
                          "timesheetUI.HeaderSearchTimesheet.FilterLabel"
                        ),
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("mdi-tune")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isFilterOpen,
        callback: function ($$v) {
          _vm.isFilterOpen = $$v
        },
        expression: "isFilterOpen",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "d-flex justify-center flex-column align-center" },
              [
                _c(
                  "span",
                  { staticClass: "pt-5 pb-4 font-weight--500 primary--text" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.HeaderSearchTimesheet.FilterPopupLabel"
                        )
                      )
                    ),
                  ]
                ),
                _c(
                  "v-chip-group",
                  {
                    attrs: {
                      column: "",
                      multiple: "",
                      role: "group",
                      "aria-label": "Filter options",
                    },
                    model: {
                      value: _vm.selectedFilters,
                      callback: function ($$v) {
                        _vm.selectedFilters = $$v
                      },
                      expression: "selectedFilters",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "justify-center v-slide-group__content" },
                      _vm._l(_vm.globalFilterOptions, function (tag) {
                        return _c(
                          "v-chip",
                          {
                            key: tag.label,
                            attrs: {
                              "aria-label": tag.label,
                              value: tag,
                              disabled: _vm.disabledFilters.includes(tag.label),
                              role: "button",
                              "aria-pressed": `${_vm.selectedFilters.includes(
                                tag
                              )}`,
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { color: "primary", size: "18" },
                              },
                              [_vm._v(_vm._s(tag.icon))]
                            ),
                            _vm._v(" " + _vm._s(tag.label) + " "),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-4 py-6" },
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight--600 timesheet-entry__actions--save px-5 ml-auto",
                  attrs: { rounded: "", depressed: "", color: "primary" },
                  on: { click: _vm.handleApplyFilters },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "timesheetUI.HeaderSearchTimesheet.BtnApplyFilters"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }