export default {
  5011: 'You are not allowed to enter time before your agency start date or before your hire date',
  5012: 'You are not allowed to enter time before your agency start date or before your hire date',
  5013: 'Oops, Your billable indicator has changed. Please amend the duration to 0, re-add the job with the correct billable indicator and re-enter your time.',
  5014: 'Oops, You are no longer allowed to enter time for this job, workcode and billable indicator. You are only allowed to enter or change your hours to 0.',
  5015: 'Oops, Your job id has changed. Please amend the duration to 0, re-add the job with the correct job id and re-enter your time.',
  5016: `<div><h4 class='mt-2 mb-6 alert-title--error'>Oops, you can no longer enter or submit time on this job, please zero out your time and try again</h4><p class='font-weight--800'>Here are the reasons why ...</p><ol><li>The job is no longer open for time entry</li><li>Your work code has changed &amp; you need to use the correct work code</li><li>The Billable Indicator on the job has changed &amp; you need to use the correct job with the correct billable indicator</li><li>Your agency no longer allows for time entry on a task in draft state</li></ol></div>`,
  5017: 'This job is not represented under your current agency.',
  5018: `Sorry, You can't record time for this date. If you need to, please contact your local support team.`,
  5019: 'Sorry, Your agency is not enabled for timesheeting yet.',
  5020: `Sorry, We like your 'rOar' but you're not permitted to enter time before your agency start date`,
  5021: '<b>Already approved</b><br/> You cannot edit time that has already been approved.',
  5024: 'Oops You are no longer approved to enter time against this task <p class="font-weight--700 my-2">{data}</p> Please follow up directly with your resource manager to resolve',
  5027: 'Sorry. The hours cap has been reached on this job. Please refer to your Finance team for further assistance.',
  5028: 'Oops, You can only enter time against Double overtime tasks on a Sunday or Public holiday.',
  5029: 'Oops, You no longer have access to enter time on this job because you have changed agencies. Please amend the duration to 0, add a job for your new agency and enter your time.',
  5030: `You're not able to enter time against this job as it is not open for time entry on this day.`,
  5031: `You're not able to enter time against this job as it is no longer open for time entry.`,
  5033: 'Oops, You can only enter time against regular time or flex time tasks where you are not over your daily schedule or on a Public holiday.',
  5036: `<p class=mb-1><b>Easy tiger!</b><br/> </p>You can't enter future time on this job.`,
  5037: `Sorry, You can't record time for this date. If you need to, please contact your local support team.`,
  5038: `Sorry, We can not find your timesheet approver in our database. Please contact your local support team.`,
  5039: `Oops, You can only enter time against Overtime tasks on a Saturday or where you are over your daily schedule.`,
  5040: `Oops, You can only submit time against Overtime tasks on a Saturday or where you are over your daily schedule.`,
  5041: `You can enter timesheets starting from {data}`,
  5042: `Sorry, We like your 'rOar' but you're not permitted to enter time before your start date.`,
  5010: `You can't submit timesheets entered before {data}.`,
  5043: `You're not able to submit time against the following jobs as they are no longer open for time entry {data} <br>`,
  5044: `You can enter timesheets starting from {data}.`,
  5046: 'Cross Company Rule for this user job is not found or deleted. Please contact your local support team.',
  5009: 'This job is already added to your timesheet.',
  5048: `Relax! It's the weekend. Your agency does not permit time entries for weekends.`,
  5004: 'There are some timesheets with duration. To please zero out all timesheets your job from your week or refresh your scren.',
  5050: 'This timesheet entry is already approved. Please refresh your screen to see the latest state.',
  5049: 'The job has been removed from your timesheet. Please refresh your browser, close other tabs the application is open in and try again.',
  5047: 'This job is already added to your timesheet.',
  5064: 'Please contact your Project Manager to ask them to add you to this project in rOar so you can add your time.',
  5065: `<h3 class='mb-6 error--text font-weight--600'>We didn’t find any jobs to copy from last week</h3><div class='mb-2'><b>Here are the reasons why...</b></div><div class='mb-2'>1-The jobs did not have time entered against them</div><div class='mb-2'>2-The jobs are no longer open for time entry</div>`,
  5002: `Quantity Required`,
  5003: 'Location is required',
  5052: 'The job has been removed from your timesheet. Please refresh your browser, close other tabs the application is open in and try again.',
  5053: 'Agency Rule Not Found',
  5054: 'Workcode not found',
  5055: `We couldn't find any timesheets to submit. Please refresh your browser, close other tabs the application is open in and try again.`,
  5056: 'Duplicate Altair Job Number',
  5057: `<h2 class='mt-2 mb-8 alert-title alert-title--error'>Oops</h2> <p>You cannot enter more than 100%</p>`,
  5061: 'Timesheet ID does not match Job ID',
  5062: `Ahem! It's holiday time. You cannot enter time for bank holidays.`,
  5063: `You're not able to enter time against this job as it is not open for time entry on this day.`,
  5066: `The rule is not enabled, therefore you cannot timesheet against this job.`,
  5026: `You're not able to enter time against this job as it is not open for time entry on this day.`,
  5067: `Non Delivery person are not allowed to track time on a bench project. Please select another project.`,
  5068: `Director and above are not allowed to track time on a Bench Project. Please select another project.`,
  5069: `Industry aligned person are not allowed to track time on a Bench Project. Please select another project.`,
  5070: `Interns are not allowed to track time on a Bench Project. Please select another project.`,
  5071: `You are showing in Career Settings as being on Leave of Absence and are not allowed to track time on a Bench Project. Please work with your people strategist to correct your Career Settings record or select another project.`,
  5072: `You are fully allocated on another project for this day. Please select another project.`,
  5073: `Contractors are not allowed to track time on a bench project. Please select another project.`,
  5074: `You are not allowed to track time to a bench project on a weekend.`,
  5075: `You are not allowed to track time on a bench project of another agency.`,
  5076: `The start and due dates of the project are not mentioned. Please update the project details accordingly.`,
  5077: `You are not allowed to save time outside the project duration. Please enter the time between {projectStartDate} and {projectEndDate}.`,
  5078: `The job type associated with this project is not configured to derive the billable indicator. Kindly reach out to the system administrator.`,
  5079: `Project does not exist in rOar. Please contact the system administrator for assistance.`,
  5080: `Timesheet entry is restricted for {jobID} since the job's brand has been updated.`,
  5081: `Timesheet entry not allowed as this date falls outside timesheet entry allowable window`,
  5082: `Timesheet entry not allowed as this date falls outside timesheet entry allowable window`,
  5083: `Your allocated time frame for this project runs {startDate} to {endDate}.<br/>Please adjust your time entry to fall within this timeframe.`,
  5085: `Please select Work From Home`,
  5087: `Timesheets are not allowed for selected date.`,
}
