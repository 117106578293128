<template>
  <div v-if="!loadingFeatures">
    <v-expansion-panels flat accordion :disabled="isTimesheetBeingCorrected">
      <v-expansion-panel
        v-for="(item, index) in timesheetExists"
        :key="item.id"
        @change="() => handleChange(item, index)"
      >
        <v-expansion-panel-header hide-actions ref="expansion_header">
          <template v-slot:default="{ open }">
            <div ref="header" :data-expanded="open">
              <div class="d-flex justify-space-between mb-1 align-center">
                <div class="font-weight--500">
                  <v-icon
                    :size="18"
                    color="primary"
                    :style="{ transform: open ? 'rotate(90deg)' : 'rotate(0)' }"
                    >mdi-chevron-right</v-icon
                  >
                  <template v-if="item.isPercentageEntry">
                    <span class="font-weight--600">
                      {{
                        beforeEdit[index].durationPercentageValue ??
                        item.durationPercentageValue
                      }}%
                    </span>
                    <span>{{ $t('timesheetUI.TableColumns.Duration') }}</span>
                  </template>
                  <template v-else>
                    <span class="font-weight--600">
                      {{
                        beforeEdit[index].durationValue ?? item.durationValue
                      }}h
                    </span>
                    <span>{{ $t('timesheetUI.TableColumns.Duration') }}</span>
                  </template>
                </div>
                <div
                  v-if="!isTemporaryExternalEmployee"
                  :class="`status-pill status-pill--${item.timesheetStatus?.toLowerCase()}`"
                >
                  <span v-if="item.timesheetStatus === 'Unsubmitted'">{{
                    $t('timesheetUI.Status.Unsubmitted')
                  }}</span>
                  <span v-if="item.timesheetStatus === 'Submitted'">{{
                    $t('timesheetUI.Status.Submitted')
                  }}</span>
                  <span v-if="item.timesheetStatus === 'Approved'">{{
                    $t('timesheetUI.Status.Approved')
                  }}</span>
                  <span v-if="item.timesheetStatus === 'Rejected'">{{
                    $t('timesheetUI.Status.Rejected')
                  }}</span>
                </div>
              </div>

              <div v-if="!open">
                <div class="mb-2">
                  <v-icon :size="12" color="primary"
                    >mdi-clipboard-text-outline</v-icon
                  >
                  <span
                    class="font-size--12"
                    v-if="timesheetDetailFeatures.activity.required"
                  >
                    {{ item.activity }} -
                    {{ item.activityName }}
                  </span>
                  <span class="font-size--12" v-else-if="item.workCode">
                    {{
                      item.jobTitleForBilling
                        ? `${item.jobTitleForBilling} - ${item.jobTitleForBillingName}`
                        : `${item.workCode} - ${item.workCodeName}`
                    }}
                  </span>
                </div>
                <div class="d-flex justify-space-between">
                  <div class="font-size--10 accent--text font-weight--600">
                    {{ item.billableIndicator }}
                  </div>
                  <div
                    class="font-weight--600 d-flex align-center"
                    v-if="item.location"
                  >
                    <v-icon :size="15" color="primary"
                      >mdi-map-marker-outline</v-icon
                    >
                    <span
                      >{{ item.location }} {{ item.wfh ? '- WFH' : '' }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:actions="{ open }">
            <div>{{ $t('timesheetUI.Footer.actions') }}: {{ open }}</div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="px-4 py-3">
            <timesheet-entry-detail
              v-if="timesheetDetail"
            ></timesheet-entry-detail>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="px-6 py-3 info rounded-lg final-count mt-4 font-size--16">
      <span class="font-weight--600"
        >{{ duration
        }}{{ this.agencyData.isPercentageAgency ? '%' : 'h' }}</span
      >
      {{ $t('timesheetUI.Footer.FooterTotalLabel') }}
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import TimesheetEntryDetail from '../time-entry/TimesheetEntryDetail.vue'
import { getTimesheets } from '@Shared/filters'

export default {
  name: 'TimsheetEntryTable',
  components: { TimesheetEntryDetail },
  watch: {
    loadingFeatures(val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs?.expansion_header[0]?.$el.focus()
        })
      }
    },
  },
  data() {
    return {
      timer: null,
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetExists',
      'loadingFeatures',
      'savingTimesheet',
      'timesheetDetailFeatures',
      'timesheetDetail',
      'beforeEdit',
      'timesheetSelectedJob',
      'timesheets',
      'agencyData',
      'isTimesheetBeingCorrected',
    ]),
    ...mapGetters('storeTimesheets', ['isTemporaryExternalEmployee']),
    duration() {
      return (
        this.getTimesheets(
          this.timesheets,
          this.timesheetSelectedJob.job.id,
          this.timesheetSelectedJob.date,
          'durationValue'
        ) || 0
      )
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setTimesheetDetail',
      'resetTableToSave',
      'setIsTimesheetBeingCorrected',
      'setIsTimesheetBeingReviewed',
    ]),
    getTimesheets,
    handleChange(item, index) {
      this.resetTableToSave()
      this.setTimesheetDetail({ data: item, openDrawer: true })
      this.setIsTimesheetBeingCorrected(false)
      this.setIsTimesheetBeingReviewed(false)
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        if (!this.$refs.header[index].dataset.expanded) {
          this.setTimesheetDetail({ data: null, openDrawer: true })
        }
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel {
  border: 1px solid $border-color;
  margin-bottom: 16px;
  border-radius: 8px !important;
  &:last-child {
    margin-bottom: 0;
  }
}

.final-count.info {
  border: 1px solid $border-color !important;
}

// .v-expansion-panel--active {
//   border-radius: 0px !important;
//   margin: 0;
//   ~ .v-expansion-panel {
//     margin: 0px;
//     border-radius: 0px !important;
//     &:last-child {
//       border-bottom-left-radius: 8px !important;
//       border-bottom-right-radius: 8px !important;
//     }
//   }
//   &:first-child {
//     border-top-left-radius: 8px !important;
//     border-top-right-radius: 8px !important;
//   }
//   &:last-child {
//     border-bottom-left-radius: 8px !important;
//     border-bottom-right-radius: 8px !important;
//   }

//   .v-expansion-panel-header {
//     border-radius: 0 !important;
//     border-bottom: 1px solid $border-color;
//   }
// }
// //select previous panels
// .v-expansion-panel:has(~ .v-expansion-panel--active) {
//   margin: 0;
//   border-radius: 0 !important;
//   &:first-child {
//     border-top-left-radius: 8px !important;
//     border-top-right-radius: 8px !important;
//   }
// }
.v-expansion-panel-header {
  padding: 8px 10px;
  border-radius: 8px !important;
  min-height: auto !important;
  &:focus {
    outline: 1px solid #367af6;
  }
}
</style>
