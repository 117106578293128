var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex" }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-center flex-wrap",
        staticStyle: { "flex-basis": "max(calc(0.6 * 100vw), 800px)" },
      },
      [
        _c("h1", { staticClass: "text-h1 font-weight--600 mr-7" }, [
          _vm._v(" " + _vm._s(_vm.$t("timesheetUI.PanelTitle")) + " "),
        ]),
        _c("GlobalSearch"),
      ],
      1
    ),
    _c("div", [_c("div", { staticClass: "ml-5" }, [_c("SearchJob")], 1)]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }