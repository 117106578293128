var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.copyRestOfWeekSelected
    ? _c("div", { staticClass: "mb-10 manage-pref-setting-select" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c(
              "v-label",
              {
                staticClass:
                  "font-weight--600 font-size--12 d-flex align-center gap-2 flex-grow-1",
                attrs: {
                  for: "locationPreferenceSelect",
                  "aria-label": `Set this location options menu: selected ${_vm.selectedValue}`,
                  "aria-live": _vm.isUpdated ? "polite" : "off",
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "timesheetUI.EntryFormLabels.TimesheetEntryLocationSettingsLabel"
                    )
                  ) + " "
                ),
              ]
            ),
            _c("LearnMore"),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex align-center gap-2 mt-2" },
          [
            _c("v-select", {
              staticClass: "mt-0",
              attrs: {
                id: "locationPreferenceSelect",
                role: "listbox",
                items: _vm.$t(
                  "timesheetUI.EntryFormLabels.TimesheetWeeklySettings"
                ),
                "item-text": "text",
                dense: "",
                outlined: "",
                height: 28,
                "aria-label": `Set this location options menu: selected ${_vm.selectedValue}`,
                "menu-props": {
                  offsetY: true,
                },
                "hide-details": "",
                "append-icon": "mdi-chevron-down",
              },
              on: {
                change: _vm.handleUpdate,
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    )
                      return null
                    return _vm.announceChange.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    )
                      return null
                    return _vm.announceChange.apply(null, arguments)
                  },
                ],
              },
              model: {
                value: _vm.selectedValue,
                callback: function ($$v) {
                  _vm.selectedValue = $$v
                },
                expression: "selectedValue",
              },
            }),
            _c(
              "div",
              {
                staticClass: "sr-only",
                attrs: { "aria-live": "polite", "aria-atomic": "true" },
              },
              [_vm._v(" " + _vm._s(_vm.selectedValue) + " ")]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }