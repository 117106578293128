import moment from 'moment'
import {
  getBillableIndicatorCode,
  getBillableIndicatorName,
  filterJobsByVisibility,
} from '@Shared/filters'
import { cloneDeep, omit } from 'lodash'

export default {
  setSelectedDate(state, payload) {
    state.currentTime = moment(payload)
    state.isTimeEntryDrawerOpen = false
    state.timesheetCellDetails.clear()
    state.isInlineErrorResolved = {}
  },

  setTimesheets(state, payload) {
    state.timesheets = payload.filter((a) =>
      a.isPercentageEntry
        ? a.selfCorrectedHours < 0
          ? (a.durationPercentageValue =
              parseFloat(a.durationPercentageValue) < 0
                ? parseFloat(a.durationPercentageValue)
                : -parseFloat(a.durationPercentageValue))
          : parseFloat(a.durationPercentageValue) > 0
        : parseFloat(a.durationValue) > 0 ||
          a.isSelfCorrected ||
          a.selfCorrectedHours < 0
    )

    state.timesheets.forEach((a) => {
      a.billableIndicator = getBillableIndicatorName(a.billableIndicator)
      if (a.selfCorrectedHours < 0) {
        a.durationValue = a.selfCorrectedHours
      }
      const selfCorrectedObj = state.timesheets.find(
        (b) =>
          a.selfCorrectedID === b.id &&
          b.selfCorrectedHours == 0 &&
          b.timesheetStatus == 'Approved'
      )
      if (selfCorrectedObj)
        selfCorrectedObj.durationValue = Math.abs(a.selfCorrectedHours)
    })

    state.replaceDurationValue = false
  },

  setTimesheetJobs(state, payload) {
    payload = window._.sortBy(payload, ['jobGroupName'])
    state.timesheetJobs = payload
  },

  setTimesheetJobsOg(state, payload) {
    state.timesheetJobsOg = JSON.parse(JSON.stringify(payload))
  },

  setUnfilteredTimesheets(state, payload) {
    state.unfilteredTimesheets = payload
  },
  setUnfilteredJobs(state, payload) {
    state.unfilteredJobs = payload
  },

  setTimesheetDetail(state, payload) {
    if (payload.data) {
      payload.data.toSave = true
      payload.data.disablePPMSelect = false
    } else {
      state.selectedPPMTask = {}
    }

    state.timesheetDialogVisibility = payload.openDrawer || false
    state.isTimeEntryDrawerOpen = payload.openDrawer || false
    state.timesheetDetail = payload.data || null
  },

  setTimesheetSelectedJob(state, payload) {
    // state.timesheetDialogVisibility = !!payload || false
    state.isTimeEntryDrawerOpen = !!payload.openDrawer || false
    state.timesheetSelectedJob = payload
  },

  setTimesheetExists(state, payload) {
    state.timesheetExists = payload.length === 0 ? false : payload
  },
  refreshTimesheetExists(state, payload) {
    if (state.isTimeEntryDrawerOpen) {
      state.timesheetExists = state.timesheets.filter(
        (a) =>
          payload.includes(a.id) &&
          moment(a.reportedDate.split('T')[0]).isSame(
            state.timesheetSelectedJob.date.split('T')[0]
          )
      )
      state.isTimeEntryDrawerOpen = state.timesheetExists.length !== 0
    }
  },
  revertTimesheet(state, payload) {
    if (payload) {
      const arrayToMutate = JSON.parse(JSON.stringify(state.timesheets))

      payload.forEach((b) => {
        const found = arrayToMutate.find((a) => a.id === b.id)

        if (found) {
          found.durationValue = b.durationValue
          found.durationPercentageValue = b.durationPercentageValue
          found.task = b.task
          found.comment = b.comment
        }
      })

      state.timesheets = arrayToMutate
    }
  },

  setLoading(state, payload) {
    state.loading = payload
  },

  setOnBehalfOfMappings(state, payload) {
    payload = window._.sortBy(payload, ['targetUserName'])
    state.onBehalfOfMappings = payload
  },

  setAltairCodeOBO(state, payload) {
    state.altairCodeOBO = payload
    state.absenceModule =
      payload === state.altairCodeOBO || payload == state.altairCode
  },

  setUserData(state, payload) {
    state.userData = payload || {}
  },

  setBeforeEdit(state, payload) {
    state.beforeEdit = payload
  },

  setTableToSave(state, payload) {
    state.validateAll = true
    payload.toSave = true
  },
  resetTableToSave(state) {
    if (state.timesheetExists) {
      let resetTimesheet = state.timesheetExists.map((a) => {
        a.toSave = false
        return a
      })
      state.timesheetExists = resetTimesheet
    }
  },

  setTimesheetDeleteError(state, payload) {
    state.showDeleteError = payload.dialog
    state.deletingData = payload.data
  },

  setTimesheetSubmitConfirmation(state, payload) {
    state.showSubmitConfirmation = payload.dialog
    state.submittedData = payload.data
  },

  setObserver(state, payload) {
    state.observer = payload
  },

  setLocation(state, payload) {
    state.timesheetDetail.location = payload.label
    state.timesheetDetail.locationCode = payload.value
  },

  setHasTimeEntryChanged(state, payload) {
    state.hasTimeEntryChanged = payload.e

    if (payload.data) {
      const clone = cloneDeep(state.timesheetDetail)
      state.selectedPPMTask = cloneDeep(payload.data)
      state.timesheetDetail = {}

      delete clone.jobTitleForBilling
      delete clone.jobTitleForBillingName
      delete clone.workCode
      delete clone.workCodeName

      clone.jobTitleForBillingName = payload.data.jobTitleForBillingName
      clone.jobTitleForBilling = payload.data.jobTitleForBilling
      clone.workCode = payload.data.workCode
      clone.workCodeName = payload.data.workCodeName

      if (payload.data.billableIndicator) {
        clone.billableIndicator = payload.data.billableIndicator
      }

      state.timesheetDetail = clone

      if (payload.data.billableIndicator) {
        state.timesheetDetail.billableIndicator = payload.data.billableIndicator
      }
    }
  },

  setDialogWidth(state, payload) {
    state.dialogWidth = payload
  },

  setSearchTimesheetJobs(state, payload) {
    state.searchTimesheetJobs = payload?.trim()
  },

  setPerformRemoveButtonFocus(state, payload) {
    state.performRemoveButtonFocus = payload
  },

  setTimesheetEntryFieldsForReview(state) {
    state.timesheetDetailOld = cloneDeep(state.timesheetDetail)
  },

  setIsTimesheetBeingCorrected(state, payload) {
    state.isTimesheetBeingCorrected = payload
    if (state.timesheetDetail && state.timesheetDetail.disablePPMSelect) {
      const disablePPMSelect = false
      state.timesheetDetail = { ...state.timesheetDetail, disablePPMSelect }
      state.timesheetDetailBillableIndicators = [
        {
          label: state.timesheetDetail.billableIndicator,
          value: getBillableIndicatorCode(
            state.timesheetDetail.billableIndicator
          ),
          selected: false,
        },
      ]
    }
  },

  setTimesheetDetailOld(state, payload) {
    state.timesheetDetailOld = payload
  },

  setIsTimesheetBeingReviewed(state, payload) {
    state.isTimesheetBeingReviewed = payload
  },

  setIsCorrectionValid(state, payload) {
    state.isCorrectionValid = payload
  },

  setTimesheetReviewedValue(state) {
    state.replaceDurationValue = true
  },

  setSelfConsentSubmitDialog(state, payload) {
    state.showSelfConsentSubmitDialog = payload
  },

  setSelfConsentData(state, payload) {
    state.selfConsentData = payload
  },
  // TA-363
  setFilteredJobs(state, selectedOption) {
    state.timesheetJobs = filterJobsByVisibility(
      state.deDupTimesheetJobsOg,
      selectedOption,
      state.timesheetVisibilityOptionsMap
    )
  },

  setDeDupTimesheetJobs(state, jobId) {
    const found = state.deDupTimesheetJobsOg.find((job) => job.id == jobId)
    if (found) {
      found.isJobVisible = true
    }
  },

  setFreelancerWorkCodes(state, value) {
    state.timesheetDetailFeatures.workcode.data.workCodes = value
    const selectedTrueWorkCode =
      state.timesheetDetailFeatures.workcode.data.workCodes.find(
        (wc) => wc.selected
      )
    if (selectedTrueWorkCode) {
      state.timesheetDetail.workCode = selectedTrueWorkCode
        ? selectedTrueWorkCode.value
        : null
      state.timesheetDetail.workCodeName = selectedTrueWorkCode
        ? selectedTrueWorkCode.label.split(' - ')[1]
        : null
    }
  },

  setTimesheetHideJobError(state, payload) {
    state.showHideJobError = payload.dialog
    state.hideJobData = payload.data
  },

  setTimesheetEditJobError(state, payload) {
    state.showEditHiddenJobError = payload.dialog
    state.editHiddenJobData = payload.data
  },

  setTimesheetVisibilityFilter(state, value) {
    state.timesheetVisibilityFilter = value
  },

  setOnBehalfOfUser(state, payload) {
    state.altairCodeOBO = payload.altairCodeOBO
    state.OBOUserName = payload.OBOUserName
  },

  showTimeEntryDrawer(state) {
    state.isTimeEntryDrawerOpen = true
  },

  hideTimeEntryDrawer(state) {
    state.isTimeEntryDrawerOpen = false
    state.isTimeEntryDrawerExpanded = false
  },

  expandTimeEntryDrawer(state) {
    state.isTimeEntryDrawerExpanded = true
  },

  collapseTimeEntryDrawer(state) {
    state.isTimeEntryDrawerExpanded = false
  },

  setJobsSortOrder(state, payload) {
    state.jobsSortOrder = payload
  },

  setPPMTasksByJobId(state, payload) {
    const localPPMTasksByJobId = cloneDeep({
      ...state.PPMTasksByJobId,
      [payload.jobId]: payload.value,
    })
    state.PPMTasksByJobId = localPPMTasksByJobId
  },

  setAltairTasksByJobId(state, payload) {
    const localAltairTasksByJobId = cloneDeep({
      ...state.altairTasksByJobId,
      [payload.jobId]: payload.value,
    })
    state.altairTasksByJobId = localAltairTasksByJobId
  },

  clearPPMAndAltairTasks(state) {
    state.PPMTasksByJobId = {}
    state.altairTasksByJobId = {}
  },

  showPreferenceDialog(state) {
    state.preferenceDialogOpen = true
  },

  closePreferenceDialog(state) {
    state.preferenceDialogOpen = false
  },

  setInlineErrorStatus(state, payload) {
    state.isInlineErrorResolved = {
      ...state.isInlineErrorResolved,
      [payload.jobId]: {
        isResolved: payload.isResolved,
        type: payload.type,
      },
    }
  },
  setMinDateForTimesheetNavigation(state, payload) {
    state.minDateForTimesheetNavigation = payload
  },

  setShowJobListPopupFromBannerLink(state, status) {
    state.showJobListPopupFromBannerLink = status
  },

  setJobListAssignedJobsSearchKeyword(state, keyword) {
    state.jobListAssignedJobsSearchKeyword = keyword
  },

  setAlertWeekErrors(state, error) {
    const isAvailable = state.alertWeekErrors.find(
      (alert) => alert.date === error.date
    )
    if (!isAvailable) state.alertWeekErrors = [...state.alertWeekErrors, error]
  },

  setGlobalSearchSelectedFilters(state, payload) {
    state.globalSearchSelectedFilters = payload
  },

  setFilterOpen(state, payload) {
    state.filterOpen = payload
  },

  addCellTimesheetDetail(state, payload) {
    state.timesheetCellDetails.set(payload.jobId, payload.details)
  },

  removeCellTimesheetDetail(state, jobId) {
    if (state.timesheetCellDetails.has(jobId)) {
      state.timesheetCellDetails.delete(jobId)
    }
  },
  timesheetCellDetailsUpdateUserSettings(state, { jobId, locationWfhDetails }) {
    const details = state.timesheetCellDetails.get(jobId)

    let sameDateDetails = {}
    if (
      state.timesheetDetail &&
      state.timesheetDetail.reportedDate === details.reportedDate &&
      state.timesheetDetail.userJobId === details.userJobId
    ) {
      sameDateDetails = { ...state.timesheetDetail }
    }
    if (details) {
      state.timesheetCellDetails.set(jobId, {
        ...details,
        durationValue: details.durationValue || sameDateDetails?.durationValue,
        durationPercentageValue:
          details.durationPercentageValue ||
          sameDateDetails?.durationPercentageValue,
        billableIndicator:
          details.billableIndicator || sameDateDetails?.billableIndicator,
        workCodeName: details.workCodeName || sameDateDetails?.workCodeName,
        workCode: details.workCode || sameDateDetails?.workCode,
        location: locationWfhDetails.location,
        locationCode: locationWfhDetails.locationCode,
        locationLabel: locationWfhDetails.location,
        wfh: locationWfhDetails.wfh,
      })
    }
  },

  setShowAssignedJobsCountInfoBanner(state, status) {
    state.showAssignedJobsCountInfoBanner = status
  },

  setPreparingAutoSave(state, payload) {
    state.preparingForAutoSave = {
      ...state.preparingForAutoSave,
      [payload]: true,
    }
  },

  removePreparingAutoSave(state, payload) {
    state.preparingForAutoSave = omit(state.preparingForAutoSave, payload)
  },

  setUpdateCellValuesAfterTimsheetUpdateFromDrawer(state) {
    state.updateCellValuesAfterTimsheetUpdateFromDrawer = Date.now()
  },
  setActiveCell(state, payload) {
    state.activeCell = payload
  },
  setTimeEntryError(state, payload) {
    const errorExists = state.timeEntryError.some((a) => {
      return JSON.stringify(a) === JSON.stringify(payload)
    })

    if (!errorExists) {
      state.timeEntryError.push(payload)
    }
  },
  removeTimeEntryError(state, payload) {
    const errorsFiltered = state.timeEntryError.filter((a) => {
      return (
        a.jobId === payload.jobId &&
        a.date === payload.reportedDate &&
        (payload.msg ? a.msg === payload.msg : true)
      )
    })

    state.timeEntryError = errorsFiltered
  },
  setCopyRestOfWeekSelected(state, payload) {
    state.copyRestOfWeekSelected = payload || false
  },
  setExpansionPanels(state, payload) {
    state.expansionPanels = payload
  },
  setLocationWFHSaveAction(state, payload) {
    state.locationWFHSaveAction = {
      ...state.locationWFHSaveAction,
      [payload.type]: payload.action,
    }
  },

  setPendingUserLocationSettings(state, payload) {
    state.pendingUserLocationSettings = payload
  },

  setPendingUserWFHSettings(state, payload) {
    state.pendingUserWFHSettings = payload
  },

  setUpdateCellStateAfterTimsheetUpdateFromLocation(state, payload) {
    state.updateCellStateAfterTimsheetUpdateFromLocation = [
      // ...state.updateCellStateAfterTimsheetUpdateFromLocation,
      ...payload,
    ]
  },

  removeFromUpdateCellStateAfterTimsheetUpdateFromLocation(state, payload) {
    state.updateCellStateAfterTimsheetUpdateFromLocation =
      state.updateCellStateAfterTimsheetUpdateFromLocation.filter((a) => {
        return a !== payload
      })
  },

  setNavigationLoading(state, status) {
    state.navigationLoading = status
  },
}
