var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isAnyJobPresentOnTimesheet
        ? _c("FooterHourRow", {
            staticClass: "mb-1",
            attrs: {
              tooltipsActive: _vm.tooltipsActive,
              submittingTimesheets: _vm.submittingTimesheets,
              IsPercentageByDay: _vm.IsPercentageByDay,
              updateSubmittingTimesheets: _vm.updateSubmittingTimesheets,
            },
          })
        : _vm._e(),
      _c("FooterActionRow", {
        attrs: {
          tooltipsActive: _vm.tooltipsActive,
          submittingTimesheets: _vm.submittingTimesheets,
          IsPercentageByDay: _vm.IsPercentageByDay,
          updateSubmittingTimesheets: _vm.updateSubmittingTimesheets,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }